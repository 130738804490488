import { defineStore } from 'pinia';

import { LinkEntity } from '@/features/links';
import {
  AttachmentFragment,
  CreateTicketInput,
  FetchAllTicketsQueryVariables,
  TicketDetailsFragment,
  TicketPreviewFragment,
  UpdateTicketInput,
} from '@/graphql/__generated__/graphql';
import { subtract } from '@/helpers/utils/arrays';
import { useApolloClient } from '@/plugins/apollo';
import { createConnection, NodeName } from '@/repositories/utils/cache';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import { TicketDetails, TicketPreview } from '../types';
import {
  allTicketsQuery,
  createTicketAttachmentMutation,
  createTicketMutation,
  createTicketStatusMutation,
  deleteTicketAttachmentMutation,
  deleteTicketMutation,
  exportTicketsMutation,
  singleTicketQuery,
  updateTicketMutation,
} from './gqlFunctions';
import { TicketStatus } from './types';

const convertToTypedTicket = (ticket: TicketDetailsFragment): TicketDetails => ({
  ...ticket,
  status: ticket.status as TicketStatus,
});

const convertPreviewToTypedTicket = (ticket: TicketPreviewFragment): TicketPreview => ({
  ...ticket,
  status: ticket.status as TicketStatus,
});

const mapTicketToPreview = (ticket: TicketDetailsFragment): TicketPreview => {
  return {
    ...ticket,
    __typename: 'TicketNode',
    status: ticket.status as TicketStatus,
  };
};

export const useTicketStore = defineStore('ticket-store', () => {
  const tickets = ref<Map<string, TicketPreview>>(new Map());
  const ticketDetails = ref<Map<string, TicketDetails>>(new Map());
  const isInitialized = ref(false);

  let latestFetchAllProjectId: string = '';

  const fetchAll = async (variables: FetchAllTicketsQueryVariables) => {
    const client = useApolloClient();
    latestFetchAllProjectId = variables.project;

    const queryResult = await client.query({
      query: allTicketsQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    // prevent race conditions for long running queries
    if (latestFetchAllProjectId !== variables.project) {
      return [];
    }
    isInitialized.value = true;

    const ticketData = flattenNodeConnection(queryResult.data.ticketsV2);
    tickets.value = new Map(
      ticketData.map((ticket) => [ticket.id, convertPreviewToTypedTicket(ticket)]),
    );
    return ticketData;
  };

  const createTicket = async (ticket: CreateTicketInput) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: createTicketMutation,
      variables: { input: ticket },
    });
    const ticketData = mutationResult.data?.createTicket?.ticket;
    if (ticketData) {
      tickets.value.set(ticketData.id, mapTicketToPreview(ticketData));
      ticketDetails.value.set(ticketData.id, convertToTypedTicket(ticketData));
    }
    return ticketData;
  };

  const updateTicket = async (ticket: UpdateTicketInput) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: updateTicketMutation,
      variables: {
        input: ticket,
      },
    });
    const ticketData = mutationResult.data?.updateTicket?.ticket;
    if (ticketData) {
      tickets.value.set(ticketData.id, mapTicketToPreview(ticketData));
      ticketDetails.value.set(ticketData.id, convertToTypedTicket(ticketData));
    }
    return ticketData;
  };

  const deleteTicket = async (ticketId: string) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: deleteTicketMutation,
      variables: {
        input: {
          id: ticketId,
          timestamp: new Date().toISOString(),
        },
      },
    });
    const wasSuccessfullyDeleted = mutationResult.data?.deleteTicket?.success;
    if (wasSuccessfullyDeleted) {
      tickets.value.delete(ticketId);
      ticketDetails.value.delete(ticketId);
    }
    return wasSuccessfullyDeleted;
  };

  const updateTicketStatus = async (ticketId: string, newStatus: TicketStatus) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: createTicketStatusMutation,
      variables: {
        input: {
          ticket: ticketId,
          status: newStatus,
          timestamp: new Date().toISOString(),
          attachments: [],
        },
      },
    });
    const ticketData = mutationResult.data?.createTicketStatusUpdate?.ticketStatusUpdate?.ticket;
    if (ticketData) {
      const convertedTicket = convertToTypedTicket(ticketData);
      tickets.value.set(ticketData.id, convertedTicket);
      const existingDetails = ticketDetails.value.get(ticketId);
      if (existingDetails) {
        ticketDetails.value.set(ticketId, { ...existingDetails, status: convertedTicket.status });
      }
    }
    return ticketData;
  };

  const updateTicketAttachments = async (ticketId: string, attachments: AttachmentFragment[]) => {
    const existingAttachments = flattenNodeConnection(
      ticketDetails.value.get(ticketId)?.attachments,
    );

    const client = useApolloClient();

    const attachmentsIdsToDelete = subtract(existingAttachments, attachments).map(
      (attachment: AttachmentFragment) => attachment.id,
    );
    const attachmentIdsToCreate = subtract(attachments, existingAttachments).map(
      (attachment: AttachmentFragment) => attachment.id,
    );

    const createResults = attachmentIdsToCreate.map((attachment) =>
      client.mutate({
        mutation: createTicketAttachmentMutation,
        variables: {
          input: {
            ticket: ticketId,
            attachment,
            timestamp: new Date().toISOString(),
          },
        },
      }),
    );
    const deleteResults = attachmentsIdsToDelete.map((attachment) =>
      client.mutate({
        mutation: deleteTicketAttachmentMutation,
        variables: {
          input: {
            ticket: ticketId,
            attachment,
            timestamp: new Date().toISOString(),
          },
        },
      }),
    );

    await Promise.all([...createResults, ...deleteResults]);
    const updatedTicket = ticketDetails.value.get(ticketId);
    if (updatedTicket) {
      updatedTicket.attachments = createConnection(attachments, NodeName.ATTACHMENT);
    }
  };

  const fetchTicket = async (ticketId: string): Promise<TicketDetails | undefined> => {
    if (ticketDetails.value.has(ticketId)) return ticketDetails.value.get(ticketId);

    const client = useApolloClient();
    const queryResult = await client.query({
      query: singleTicketQuery,
      variables: { id: ticketId },
      fetchPolicy: 'no-cache',
    });

    const ticketData = queryResult.data.ticket;
    /**
     * Ticket can belong to different project than previews so we
     * only set it on details. The preview data will be loaded eventually.
     */
    if (!ticketData) {
      return undefined;
    }
    const details = convertToTypedTicket(ticketData);
    ticketDetails.value.set(ticketData.id, details);

    return details;
  };

  const exportTickets = async (ticketIds: string[], fileName: string) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: exportTicketsMutation,
      variables: {
        input: {
          tickets: ticketIds,
          title: fileName,
        },
      },
    });
    const file = mutationResult.data?.createTicketExport?.ticketExport?.file;
    return file;
  };

  const useTicketsForOrder = (orderId: Ref<string>) =>
    computed(() => {
      const ticketsForOrder = Array.from(tickets.value.values()).filter(
        (ticket) => ticket.order?.id === orderId.value,
      );
      return ticketsForOrder;
    });

  return {
    createTicket,
    deleteTicket,
    exportTickets,
    fetchTicket,
    fetchAll,
    isInitialized,
    tickets,
    ticketDetails,
    updateTicket,
    updateTicketStatus,
    useTicketsForOrder,
    updateTicketAttachments,
    setTicketLinks: (ticketId: string, links: LinkEntity[]) => {
      const details = ticketDetails.value.get(ticketId);
      if (details) {
        ticketDetails.value.set(ticketId, { ...details, links });
      }
    },
  };
});
