import { DomClassList } from '@bryntum/schedulerpro';
import { DependencyType } from '@koppla-tech/scheduling-engine';
import { addMinutes, isBefore } from 'date-fns';
import { Composer } from 'vue-i18n';

import { OrderEntity, TradeEntity } from '@/common/types';
import { BaseActualStatus, getBaseActualColor } from '@/features/basePlan';
import { useBaseDryingTime } from '@/features/basePlan/composables';
import { getDependencyIdOfTypeName } from '@/helpers/orders/dependencies';
import { StatusReport } from '@/helpers/orders/status';
import { getHexFromCssVariable } from '@/helpers/utils/colors';
import { NodeName } from '@/repositories/utils/cache';
import {
  BaseScheduleEventParser,
  schedulerClassConfig,
  schedulerIconConfig,
} from '@/services/store/schedule/parsers/base';
import { ScheduleModelParser } from '@/services/store/schedule/parsers/types';
import {
  DurationUnit,
  SchedulerDependency,
  SchedulerEvent,
  SchedulerEventAppearance,
  SchedulerEventColorType,
} from '@/services/store/schedule/types';

import { OrderEventParser } from './order';

export class DryingBreakEventParser
  extends BaseScheduleEventParser
  implements
    ScheduleModelParser<OrderEntity, [Composer, Map<string, TradeEntity>], SchedulerEvent | null>
{
  public entityToModel(
    order: OrderEntity,
    i18n: Composer,
    trades: Map<string, TradeEntity>,
  ): SchedulerEvent | null {
    if (!order.dryingBreak) return null;

    const { dryingBreak } = order;

    const orderParser = new OrderEventParser();
    const startDate = new SchedulingDate(order[orderParser.getFinishDateKey(order)]);

    const dryingBreakBaseEventData = this.getDryingBreakBaseEventData(
      order.id,
      startDate,
      dryingBreak.duration,
    );

    return {
      ...dryingBreakBaseEventData,
      name: dryingBreak.name ?? i18n.t('objects.dryingBreak.defaultName'),
      appearance: this.getAppearance(order, trades, dryingBreakBaseEventData.endDate),
      resourceId: order.wbsSection?.id ?? null,
      calendarId: null,
    };
  }

  public getDryingBreakBaseEventData(
    orderId: string,
    startDate: Date,
    duration: number,
    cls: string[] = [],
  ) {
    const { startAt, finishAt } = DryingBreakEventParser.getDryingBreakDates(startDate, duration);
    return {
      cls: new DomClassList([schedulerClassConfig[NodeName.DRYING_BREAK], ...cls]),
      draggable: false,
      duration,
      durationUnit: DurationUnit.MINUTE,
      endDate: finishAt,
      entity: NodeName.DRYING_BREAK,
      iconCls: schedulerIconConfig[NodeName.DRYING_BREAK],
      id: DryingBreakEventParser.orderIdToDryingBreakId(orderId),
      isFixed: false,
      isSelectable: false,
      resizable: 'end',
      startDate: startAt,
    };
  }

  public static getDryingBreakDates(
    startAt: Date,
    durationInMinutes: number,
  ): { startAt: Date; finishAt: Date } {
    let finishAt = addMinutes(startAt, durationInMinutes);
    const timeZoneOffsetDiff = finishAt.getTimezoneOffset() - startAt.getTimezoneOffset();

    finishAt = addMinutes(finishAt, timeZoneOffsetDiff);
    return { startAt, finishAt };
  }

  public getFakeDependency(order: OrderEntity): SchedulerDependency {
    // between order and its drying break there is a fake dependency with 0 lag that enables moving but isn't updatable
    return {
      id: DryingBreakEventParser.getFakeDependencyId(order.id),
      from: order.id,
      to: DryingBreakEventParser.orderIdToDryingBreakId(order.id),
      type: getDependencyIdOfTypeName(DependencyType.FS),
      lag: 0,
      lagUnit: 'm',
      fake: true,
    };
  }

  public static getFakeDependencyId(orderId): string {
    return `${orderId}-${DryingBreakEventParser.orderIdToDryingBreakId(orderId)}`;
  }

  public static orderIdToDryingBreakId(orderId: string): string {
    return `${orderId}-drying-break`;
  }

  public static dryingBreakIdToOrderId(dryingBreakId: string): string {
    return dryingBreakId.replace('-drying-break', '');
  }

  private getAppearance(
    order: OrderEntity,
    trades: Map<string, TradeEntity>,
    endDate: Date,
  ): Record<number, SchedulerEventAppearance> {
    const orderEventParser = new OrderEventParser();

    return {
      [SchedulerEventColorType.TRADE]: orderEventParser.getTradeViewAppearance(order, trades),
      [SchedulerEventColorType.STATUS]: this.getStatusViewAppearance(order, endDate),
      [SchedulerEventColorType.BASE_ACTUAL]: this.getBaseActualViewAppearance(order),
    };
  }

  private getStatusViewAppearance(order: OrderEntity, endDate: Date): SchedulerEventAppearance {
    const relatedOrderStatus = order.status;
    const isInPast = isBefore(endDate, new SchedulingDate());
    const dryingTimeStatus =
      isInPast && relatedOrderStatus === StatusReport.DONE
        ? StatusReport.DONE
        : StatusReport.NOT_SET;

    return {
      cls: [`order-status-report-${dryingTimeStatus}-event`],
      eventColor: '',
    };
  }

  private getBaseActualViewAppearance(order: OrderEntity): SchedulerEventAppearance {
    const dryingTimeStatus =
      useBaseDryingTime(ref(order.id)).value?.status ?? BaseActualStatus.UNAVAILABLE;
    const stateColor = getBaseActualColor(dryingTimeStatus);

    return {
      cls: [],
      eventColor: getHexFromCssVariable(stateColor),
    };
  }
}
