import gql from 'graphql-tag';

export enum TicketStatus {
  PENDING = 'PENDING',
  REPORTED_DONE = 'REPORTED_DONE',
  REJECTED_BY_CREATOR = 'REJECTED_BY_CREATOR',
  CLOSED = 'CLOSED',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TicketPreview = gql`
  fragment TicketPreview on TicketNode {
    id
    title
    createdAt
    deadline
    description
    updatedAt
    contributorGroup {
      id
      __typename
    }
    responsibleUser {
      ...UserSimple
      __typename
    }
    status
    order {
      id
      __typename
    }
    __typename
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TicketDetails = gql`
  fragment TicketDetails on TicketNode {
    __typename
    id
    title
    createdAt
    deadline
    description
    updatedAt
    project {
      id
    }
    contributorGroup {
      __typename
      id
    }
    responsibleUser {
      ...UserSimple
      __typename
    }
    status
    order {
      __typename
      id
      name
      status
      tenantTradeVariation {
        name
      }
      wbsSection {
        id
      }
    }
    attachments {
      edges {
        node {
          ...Attachment
        }
      }
    }
    links {
      ...Link
    }
  }
`;
