import {
  IdMapping,
  Milestone,
  Order,
  OrderSchedulingEngine,
  ReducedOrder,
} from '@koppla-tech/scheduling-engine';
import { OperationNames } from 'events.schema';
import { Observer, Subject, Subscription } from 'rxjs';

import {
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  InteractiveEntityStores,
} from '@/common/types';
import { showChangedFilteredElementsNotification } from '@/features/filter-and-export';
import { TradeType } from '@/features/projectTrades';
import { showActionFailedNotification } from '@/features/realTimeCollaboration';
import { RTC_CONTROLLER_INIT_TIMEOUT } from '@/features/realTimeCollaboration/const';
import { UndoRedoQueue } from '@/features/undoRedo/queue';
import { mapDomainToSENGOrderStatus, mapStatusReportToOrderStatus } from '@/helpers/orders/status';
import { getRandomId } from '@/helpers/utils/strings';
import { LoggingService } from '@/interfaces/services';
import { ConsoleLoggingService } from '@/services';
import { useMonitoring } from '@/utils/performance/useMonitoring';

import { RTCClient } from '../rtcClient';
import { RTCClientResponseStatus } from '../rtcClient/types';
import {
  BlockingInteraction,
  ExternalProjectDataChange,
  LocalProjectChangeEventTemplate,
  RemoteProjectChangeEvent,
} from '../types';
import { RestoredEntityNotFoundError } from './errors';
import { RTCBlockingInteractionHandler } from './utils/blockingInteractionHandler';
import {
  distributeChangesToStores,
  getChangesFromEvent,
  identifyChangesThatAreFilteredOut,
} from './utils/changes';
import { convertProjectContributorGroupChangeOperationInput } from './utils/convertChangeOperation';
import { RTCEventHandler } from './utils/eventHandler';
import { RTCPollingHandler } from './utils/pollingHandler';
import { amendOrdersInEngine } from './utils/sanitize';
import { copyStoreStates, updateStateMaps } from './utils/states';

export interface RTCControllerProjectContext {
  /**
   * ID of project tenant.
   */
  tenantId: string;
  projectId: string;
}

const { measureCallbackDuration, startManualDurationMeasurement, endManualDurationMeasurement } =
  useMonitoring();

/**
 * CoreRTCController is a class that manages the business logic for real-time collaboration
 * for a single project.
 * It initializes the project state, handles remote and local project change events,
 * and manages the interaction between the local and remote project states.
 */
export class CoreRTCController {
  protected context: RTCControllerProjectContext | undefined;

  protected isInitialized = ref(false);

  protected remoteOrderSchedulingEngine = new OrderSchedulingEngine();

  protected validStoreStates: InteractiveEntityMaps = {};

  protected messageIdToCommitIdDictionary: Record<string, string> = {};

  private remoteProjectChangeChannel: Subject<RemoteProjectChangeEvent> | undefined;

  private eventHandler: RTCEventHandler;

  private blockingInteractionHandler = new RTCBlockingInteractionHandler();

  private pollingHandler = new RTCPollingHandler();

  private receivedRemoteMessageIds = new Set<string>();

  public constructor(
    private rtcClient: () => RTCClient,
    private undoRedoQueue: () => UndoRedoQueue,
    private localOrderSchedulingEngine: () => OrderSchedulingEngine,
    private entityStores: InteractiveEntityStores,
    private loggingService: LoggingService = new ConsoleLoggingService(),
  ) {
    this.eventHandler = new RTCEventHandler(
      undoRedoQueue,
      localOrderSchedulingEngine,
      entityStores,
      loggingService,
      this.messageIdToCommitIdDictionary,
    );
  }

  public async initialize(context: RTCControllerProjectContext): Promise<void> {
    if (this.isInitialized.value) {
      await this.reset();
    }
    this.context = context;
    await this.setupRTCClient(context);

    const initializationId = getRandomId();
    startManualDurationMeasurement(initializationId, `RTC Initialization`);

    // Trade sequences aren't needed for SENG initialization so we don't add it to blocking promises.
    const tradeSequenceStoreSetupPromise = this.entityStores
      .projectTradeSequenceStore()
      .fetchAll(context.projectId);

    /**
     * TODO [Tim]: consider adding projectTrades query which returns
     * project and tenant trades. Would allow us to remove project
     * tenant id from context and simplify setup.
     */
    const tenantTradeStoreSetupPromise = this.entityStores
      .tenantTradeStore()
      .fetchAll({ tenant: context.tenantId });
    const projectTradeStoreSetupPromise = this.entityStores
      .projectTradeStore()
      .fetchAll({ project: context.projectId });

    const [calendars, pauses, orders, milestones, dependencies] = await Promise.all([
      this.entityStores.calendarStore().fetchAll(context.projectId),
      this.entityStores.pauseStore().fetchAll(context.projectId),
      this.entityStores.orderStore().fetchAll(context.projectId),
      this.entityStores.milestoneStore().fetchAll(context.projectId),
      this.entityStores.orderDependencyStore().fetchAll(context.projectId),
      this.entityStores.projectContributorStore().fetchAll(context.projectId),
      this.entityStores.wbsSectionStore().fetchAll(context.projectId),
    ]);

    const reducedOrders = orders.map<ReducedOrder>((order) => {
      return {
        ...order,
        status: mapDomainToSENGOrderStatus(mapStatusReportToOrderStatus(order.status)),
      };
    });

    this.remoteOrderSchedulingEngine.initialize({
      pauses,
      orders: reducedOrders,
      milestones: milestones as Milestone[], // this conversion should be removed when removing isFixed from Scheduling Engine
      dependencies,
      calendars,
    });
    this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);

    // Now we need to await the other promises as well before setting the valid states
    await Promise.all([
      tradeSequenceStoreSetupPromise,
      tenantTradeStoreSetupPromise,
      projectTradeStoreSetupPromise,
    ]);
    this.validStoreStates = copyStoreStates(this.entityStores);

    this.isInitialized.value = true;
    endManualDurationMeasurement(initializationId);

    this.eventHandler.pendingRemoteProjectChangeEvents.forEach((event) => {
      if (event.isRemoteEvent) {
        this.pushRemoteProjectChangeEvent(event.payload as RemoteProjectChangeEvent);
      } else {
        this.onExternalDataChange(event.payload as ExternalProjectDataChange);
      }
    });

    this.pollingHandler.initialize();
  }

  private async setupRTCClient(context: RTCControllerProjectContext): Promise<void> {
    const rtcClient = this.rtcClient();
    rtcClient.connect();
    rtcClient.registerProjectChangeEventListener({
      id: 'RTCControllerProjectChangeEventListener',
      callback: (event, isOwn) => {
        if (isOwn) {
          endManualDurationMeasurement(event.messageId);
        }
        this.remoteProjectChangeChannel?.next(event);
        return this.onRemoteEvent(event, isOwn);
      },
    });
    rtcClient.registerExternalProjectDataChangeListener({
      id: 'RTCControllerExternalProjectDataChangeListener',
      callback: (payload) => {
        return this.onExternalDataChange(payload);
      },
    });
    await rtcClient.subscribeToProject(context.projectId);
  }

  public get initializationPromise(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.isInitialized.value) {
        resolve();
        return;
      }

      const stop = watch(this.isInitialized, (newValue) => {
        if (newValue) {
          resolve();
          stop();
        }
      });
    });
  }

  private async waitForInitialization(): Promise<void> {
    if (this.isInitialized.value) return;

    await Promise.race([
      this.initializationPromise,
      new Promise<void>((_, reject) =>
        setTimeout(
          () => reject(new Error('RTCControllerProjectState could not be initialized.')),
          RTC_CONTROLLER_INIT_TIMEOUT,
        ),
      ),
    ]);
  }

  protected async reset(): Promise<void> {
    this.eventHandler.reset();
    if (this.remoteProjectChangeChannel) {
      this.remoteProjectChangeChannel.complete();
      this.remoteProjectChangeChannel = undefined;
    }
    this.pollingHandler.reset();
    this.blockingInteractionHandler.reset();
    await this.rtcClient().unsubscribeFromProject();

    this.validStoreStates = {};
    this.messageIdToCommitIdDictionary = {};
    this.undoRedoQueue().reset();
    this.remoteOrderSchedulingEngine.reset();
    this.localOrderSchedulingEngine().reset();

    this.entityStores.pauseStore().reset();
    this.entityStores.milestoneStore().reset();
    this.entityStores.orderStore().reset();
    this.entityStores.calendarStore().reset();
    this.entityStores.orderDependencyStore().reset();
    this.entityStores.projectTradeSequenceStore().reset();
    this.entityStores.wbsSectionStore().reset();
    this.entityStores.projectTradeStore().reset();
    this.context = undefined;
    this.isInitialized.value = false;
    this.receivedRemoteMessageIds = new Set();
  }

  public onRemoteEvent(event: RemoteProjectChangeEvent, isOwn: boolean): void {
    const { messageId } = event;
    /**
     * Basic guard against backend sending processed message more than once.
     * Only expected when bugs are introduced but simple enough protection
     * to implement.
     */
    if (this.receivedRemoteMessageIds.has(messageId)) {
      this.loggingService.error(`Received message with id ${messageId} more than once.`, {
        code: 'DUPLICATE_RTC_MESSAGE',
      });
      return;
    }
    this.receivedRemoteMessageIds.add(messageId);
    if (isOwn) {
      this.handleOwnRemoteEvent(event);
    } else {
      this.handleOtherRemoteEvent(event);
    }
  }

  private handleOwnRemoteEvent(event: RemoteProjectChangeEvent): void {
    measureCallbackDuration(`RTC FE Validation handling ${event.operation.name}`, () => {
      if (event.status === RTCClientResponseStatus.SUCCESS) {
        this.validateProjectChangeEvent(event);
      } else {
        this.invalidateProjectChangeEvent(event);
      }
    });
    this.eventHandler.replayOutOfOrderEvents((event: RemoteProjectChangeEvent) => {
      this.handleOwnRemoteEvent(event);
    });
  }

  private handleOtherRemoteEvent(event: RemoteProjectChangeEvent): void {
    const project = this.validStoreStates.projects?.get(event.projectId);
    this.blockingInteractionHandler.setBlockingInteractionForRemoteEvent(event, project);
    if (event.status === RTCClientResponseStatus.SUCCESS) {
      this.pushRemoteProjectChangeEvent(event);
    }
  }

  public async pushRemoteProjectChangeEvent(event: RemoteProjectChangeEvent): Promise<void> {
    if (!this.isInitialized.value) {
      this.eventHandler.pendingRemoteProjectChangeEvents.push({
        isRemoteEvent: true,
        payload: event,
      });
      return;
    }

    let remoteChanges: InteractiveEntityChanges;
    try {
      const { changes } = getChangesFromEvent(
        event,
        this.remoteOrderSchedulingEngine,
        this.entityStores,
        this.validStoreStates,
      );
      remoteChanges = { ...changes };

      this.validStoreStates = updateStateMaps(this.validStoreStates, remoteChanges);
      this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);
      const { invalidEvents } = this.eventHandler.replayValidLocalEvents(event, remoteChanges, {
        initialStoreStates: this.validStoreStates,
      });
      this.eventHandler.notifyAndDropInvalidEvents(invalidEvents);
    } catch (error) {
      if (error instanceof RestoredEntityNotFoundError) {
        this.blockingInteractionHandler.setBlockingInteractionForRestoredEntityNotFoundError();
      }
      this.loggingService.error(error as Error, {
        code: 'RTCController.pushRemoteProjectChangeEvent',
      });
    }
  }

  public async pushLocalProjectChangeEvent(
    template: LocalProjectChangeEventTemplate,
    {
      commitId,
    }: {
      commitId?: string;
    } = {},
  ): Promise<InteractiveEntityChanges> {
    await this.waitForInitialization();

    startManualDurationMeasurement(
      template.messageId,
      `RTC FE Processing ${template.operation.name}`,
    );

    let localChanges: InteractiveEntityChanges;
    try {
      let changes: InteractiveEntityChanges;
      let idMappings: IdMapping[] | undefined = undefined;

      // NOTE: Delete alternative ignored for local case, as we can't delete it optimistically
      if (template.operation.name === OperationNames.DeleteProjectAlternative) {
        changes = {};
      } else {
        const transformed = getChangesFromEvent(
          template,
          this.localOrderSchedulingEngine(),
          this.entityStores,
          copyStoreStates(this.entityStores),
          false,
        );
        changes = transformed.changes;
        idMappings = transformed.idMappings;
      }
      localChanges = { ...changes };

      // set the id mappings for the trade sequence operations
      if (template.operation.name === OperationNames.CreateTradeSequence) {
        template.operation.input.idMapping = idMappings
          ? {
              activityToOrderId: idMappings![0].activityIdToOrderId,
              dependencies: idMappings![0].orderIdsToOrderDependencyId,
            }
          : null;
      } else if (template.operation.name === OperationNames.InsertTradeSequence) {
        template.operation.input.idMapping = {
          activityToOrderId: idMappings![0].activityIdToOrderId,
          dependencies: idMappings![0].orderIdsToOrderDependencyId,
        };
      } else if (template.operation.name === OperationNames.UpdateTradeSequence) {
        template.operation.input.idMappings = idMappings!.map((idMapping) => ({
          tradeSequenceInstanceId: idMapping.tradeSequenceInstanceId,
          activityToOrderId: idMapping.activityIdToOrderId,
          dependencies: idMapping.orderIdsToOrderDependencyId,
        }));
      }

      const event = this.rtcClient().prepareProjectChangeEventForPublishing(template);
      if (commitId) {
        this.messageIdToCommitIdDictionary[event.messageId] = commitId;
      }

      distributeChangesToStores(this.entityStores, changes);

      // NOTE: we don't return the actual promise here, since the validate/invalidate handling will happen separately via the socket
      this.rtcClient().publishProjectChangeEvent(event, () => {
        this.eventHandler.localProjectChangeEvents.push(event);
      });

      const { addedElementTypes, editedElementTypes } = identifyChangesThatAreFilteredOut(changes);
      if (addedElementTypes.length || editedElementTypes.length) {
        showChangedFilteredElementsNotification({ addedElementTypes, editedElementTypes });
      }

      endManualDurationMeasurement(template.messageId);
      startManualDurationMeasurement(
        template.messageId,
        `RTC BE Validation ${template.operation.name}`,
      );
    } catch (error) {
      endManualDurationMeasurement(template.messageId);
      this.loggingService.error(error as Error, {
        code: 'RTCController.pushLocalProjectChangeEvent',
      });
      throw error;
    }

    return localChanges;
  }

  private validateProjectChangeEvent(event: RemoteProjectChangeEvent): void {
    const localEvent = this.eventHandler.retrieveLocalEventFromQueue(event, () => {
      this.blockingInteractionHandler.setBlockingInteractionForOutOfOrderEventsError();
    });
    if (!localEvent) {
      return;
    }

    try {
      const { changes } = getChangesFromEvent(
        event,
        this.remoteOrderSchedulingEngine,
        this.entityStores,
        this.validStoreStates,
      );
      this.validStoreStates = updateStateMaps(this.validStoreStates, changes);
      this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);
      this.eventHandler.replayAllLocalEvents(this.validStoreStates);
    } catch (error) {
      this.loggingService.error(error as Error, {
        code: 'RTCController.validateProjectChangeEvent',
      });
    }
  }

  private invalidateProjectChangeEvent(event: RemoteProjectChangeEvent): void {
    const localEvent = this.eventHandler.retrieveLocalEventFromQueue(event, () => {
      this.blockingInteractionHandler.setBlockingInteractionForOutOfOrderEventsError();
    });
    if (!localEvent) {
      return;
    }

    showActionFailedNotification();

    try {
      const { changes: invalidChanges } = getChangesFromEvent(
        localEvent,
        this.remoteOrderSchedulingEngine,
        this.entityStores,
        this.validStoreStates,
        true,
      );
      this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);
      const { invalidEvents } = this.eventHandler.replayValidLocalEvents(
        localEvent,
        invalidChanges,
        {
          initialStoreStates: this.validStoreStates,
          checkForEventConflicts: false,
          unionizeSubsequentEventChanges: true,
        },
      );

      this.undoRedoQueue().drop(this.messageIdToCommitIdDictionary[event.messageId]);
      this.eventHandler.notifyAndDropInvalidEvents(invalidEvents);
    } catch (error) {
      this.loggingService.error(error as Error, {
        code: 'RTCController.invalidateProjectChangeEvent',
      });
    }
  }

  public onExternalDataChange(payload: ExternalProjectDataChange): void {
    if (!this.isInitialized.value) {
      this.eventHandler.pendingRemoteProjectChangeEvents.push({ isRemoteEvent: false, payload });
      return;
    }

    if (payload.name === 'TradeChange') {
      if (payload.data.isTenantChange) {
        this.entityStores.tenantTradeStore().setState(payload.data.trades);
        this.entityStores.projectTradeStore().setTenantTrades(payload.data.trades);
      } else {
        this.entityStores.projectTradeStore().setProjectTrades(payload.data.trades);
        this.entityStores
          .tenantTradeStore()
          .setState(payload.data.trades.filter((trade) => trade.type === TradeType.Tenant));
      }
    }

    if (payload.name === 'ProjectContributorGroupChange') {
      const contributorGroupStore = this.entityStores.projectContributorStore();

      contributorGroupStore.setPartialState(
        convertProjectContributorGroupChangeOperationInput(payload.data),
      );

      const assignedOrders = this.entityStores
        .orderStore()
        .assignContributorGroupIfNecessary(contributorGroupStore.contributorGroupList);
      const filterAssignedEngineOrders = (order: Order) => assignedOrders.has(order.id);
      const amendAssignedEngineOrders = (order: Order) => ({
        id: order.id,
        contributorGroup: assignedOrders.get(order.id)!,
      });
      amendOrdersInEngine(
        this.localOrderSchedulingEngine(),
        filterAssignedEngineOrders,
        amendAssignedEngineOrders,
      );
      amendOrdersInEngine(
        this.remoteOrderSchedulingEngine,
        filterAssignedEngineOrders,
        amendAssignedEngineOrders,
      );

      const unassignedOrders = this.entityStores
        .orderStore()
        .unassignContributorGroupIfNecessary(
          contributorGroupStore.contributorGroupList.map((group) => group.id),
        );
      const filterUnassignedEngineOrders = (order: Order) => unassignedOrders.has(order.id);
      const amendUnassignedEngineOrders = (order: Order) => ({
        id: order.id,
        contributorGroup: null,
      });
      amendOrdersInEngine(
        this.localOrderSchedulingEngine(),
        filterUnassignedEngineOrders,
        amendUnassignedEngineOrders,
      );
      amendOrdersInEngine(
        this.remoteOrderSchedulingEngine,
        filterUnassignedEngineOrders,
        amendUnassignedEngineOrders,
      );
    }

    if (payload.name === 'TradeReplacement') {
      this.entityStores
        .orderStore()
        .replaceTrade(
          payload.data.replacement.previousTradeId,
          payload.data.replacement.newTradeId,
        );
      this.entityStores
        .projectTradeSequenceStore()
        .replaceTrade(
          payload.data.replacement.previousTradeId,
          payload.data.replacement.newTradeId,
        );
      this.entityStores
        .tenantTradeSequenceStore()
        .replaceTrade(
          payload.data.replacement.previousTradeId,
          payload.data.replacement.newTradeId,
        );

      const filterEngineOrders = (order: Order) =>
        order.tenantTradeVariation?.id === payload.data.replacement.previousTradeId;
      const amendEngineOrders = (order: Order) => ({
        id: order.id,
        tenantTradeVariation: { id: payload.data.replacement.newTradeId },
      });
      amendOrdersInEngine(this.localOrderSchedulingEngine(), filterEngineOrders, amendEngineOrders);
      amendOrdersInEngine(this.remoteOrderSchedulingEngine, filterEngineOrders, amendEngineOrders);

      this.entityStores.tenantTradeStore().deleteTrade(payload.data.replacement.previousTradeId);
      this.entityStores.projectTradeStore().deleteTrade(payload.data.replacement.previousTradeId);
    }
  }

  public get currentBlockingInteraction(): Ref<BlockingInteraction | null> {
    return computed(() => this.blockingInteractionHandler.blockingInteraction.value);
  }

  public removeBlockingInteraction(): void {
    this.blockingInteractionHandler.removeBlockingInteraction();
  }

  public getValidStoreStates(): InteractiveEntityMaps {
    return this.validStoreStates;
  }

  public getContext(): RTCControllerProjectContext | undefined {
    return this.context;
  }

  public registerPollingListener(listener: () => void): void {
    this.pollingHandler.registerPollingListener(listener);
  }

  public unregisterPollingListener(listener: () => void): void {
    this.pollingHandler.unregisterPollingListener(listener);
  }

  public subscribeToRemoteProjectChangeEvents(
    observer:
      | Partial<Observer<RemoteProjectChangeEvent>>
      | ((value: RemoteProjectChangeEvent) => void)
      | undefined,
  ): Subscription {
    if (!this.remoteProjectChangeChannel) this.remoteProjectChangeChannel = new Subject();
    return this.remoteProjectChangeChannel.subscribe(observer);
  }
}
