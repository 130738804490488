/**
 * Hard-coded holidays list for Germany.
 * To add more:
 * 1. Add new folder with the name of the year in scripts/
 * 2. Go to https://www.feiertage.net/frei-tage.php
 * 3. Download all the csv's for the different states from the source page (+ add in the new folder)
 * 4. Use the holidayTransform.py script (need to change year in code) to generate the json file
 * 5. Copy json file to the end of the holiday list in this file
 */

export interface AvailableHolidayCountry {
  DE: AvailableHolidayState;
}

export interface AvailableHolidayState {
  BW: 'Baden-Württemberg';
  BY: 'Bayern';
  BE: 'Berlin';
  BB: 'Brandenburg';
  HB: 'Bremen';
  HH: 'Hamburg';
  HE: 'Hessen';
  MV: 'Mecklenburg-Vorpommern';
  NI: 'Niedersachsen';
  NW: 'Nordrhein-Westfalen';
  RP: 'Rheinland-Pfalz';
  SL: 'Saarland';
  SN: 'Sachsen';
  ST: 'Sachsen-Anhalt';
  SH: 'Schleswig-Holstein';
  TH: 'Thüringen';
}

export interface AvailableHolidayYear {
  2021: AvailableHolidayState;
  2022: AvailableHolidayState;
  2023: AvailableHolidayState;
}

export const states = {
  DE: {
    BW: 'Baden-Württemberg',
    BY: 'Bayern',
    BE: 'Berlin',
    BB: 'Brandenburg',
    HB: 'Bremen',
    HH: 'Hamburg',
    HE: 'Hessen',
    MV: 'Mecklenburg-Vorpommern',
    NI: 'Niedersachsen',
    NW: 'Nordrhein-Westfalen',
    RP: 'Rheinland-Pfalz',
    SL: 'Saarland',
    SN: 'Sachsen',
    ST: 'Sachsen-Anhalt',
    SH: 'Schleswig-Holstein',
    TH: 'Thüringen',
  },
};

export const holidays = {
  DE: {
    2021: {
      RP: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2021', name: 'Allerheiligen' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      SN: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '17.11.2021', name: 'Bu\u00df- und Bettag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      TH: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '20.09.2021', name: 'Weltkindertag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],

      HE: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],

      SL: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '15.08.2021', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2021', name: 'Allerheiligen' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      NI: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      BW: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '06.01.2021', name: 'Heilige Drei K\u00f6nige' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2021', name: 'Allerheiligen' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      BB: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      HH: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      MV: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      ST: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '06.01.2021', name: 'Heilige Drei K\u00f6nige' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      BE: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '08.03.2021', name: 'Frauentag' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      NW: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2021', name: 'Allerheiligen' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      BY: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '06.01.2021', name: 'Heilige Drei K\u00f6nige' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.06.2021', name: 'Fronleichnam' },
        { date: '15.08.2021', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2021', name: 'Allerheiligen' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      SH: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
      HB: [
        { date: '01.01.2021', name: 'Neujahr' },
        { date: '02.04.2021', name: 'Karfreitag' },
        { date: '05.04.2021', name: 'Ostermontag' },
        { date: '01.05.2021', name: 'Maifeiertag' },
        { date: '13.05.2021', name: 'Christi Himmelfahrt' },
        { date: '24.05.2021', name: 'Pfingstmontag' },
        { date: '03.10.2021', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2021', name: 'Reformationstag' },
        { date: '25.12.2021', name: '1. Weihnachtstag' },
        { date: '26.12.2021', name: '2. Weihnachtstag' },
      ],
    },
    2022: {
      RP: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2022', name: 'Allerheiligen' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      SN: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '16.11.2022', name: 'Bu\u00df- und Bettag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      TH: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '20.09.2022', name: 'Weltkindertag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      HE: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      SL: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '15.08.2022', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2022', name: 'Allerheiligen' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      NI: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      BW: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '06.01.2022', name: 'Heilige Drei K\u00f6nige' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2022', name: 'Allerheiligen' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      BB: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      HH: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      MV: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      ST: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '06.01.2022', name: 'Heilige Drei K\u00f6nige' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      BE: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '08.03.2022', name: 'Frauentag' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      NW: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2022', name: 'Allerheiligen' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      BY: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '06.01.2022', name: 'Heilige Drei K\u00f6nige' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '16.06.2022', name: 'Fronleichnam' },
        { date: '15.08.2022', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2022', name: 'Allerheiligen' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      SH: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
      HB: [
        { date: '01.01.2022', name: 'Neujahr' },
        { date: '15.04.2022', name: 'Karfreitag' },
        { date: '18.04.2022', name: 'Ostermontag' },
        { date: '01.05.2022', name: 'Maifeiertag' },
        { date: '26.05.2022', name: 'Christi Himmelfahrt' },
        { date: '06.06.2022', name: 'Pfingstmontag' },
        { date: '03.10.2022', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2022', name: 'Reformationstag' },
        { date: '25.12.2022', name: '1. Weihnachtstag' },
        { date: '26.12.2022', name: '2. Weihnachtstag' },
      ],
    },
    2023: {
      RP: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2023', name: 'Allerheiligen' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      SN: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '22.11.2023', name: 'Bu\u00df- und Bettag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      TH: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '20.09.2023', name: 'Weltkindertag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      HE: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      SL: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '15.08.2023', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2023', name: 'Allerheiligen' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      NI: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      BW: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '06.01.2023', name: 'Heilige Drei K\u00f6nige' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2023', name: 'Allerheiligen' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      BB: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      HH: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      MV: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      ST: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '06.01.2023', name: 'Heilige Drei K\u00f6nige' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      BE: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '08.03.2023', name: 'Frauentag' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      NW: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2023', name: 'Allerheiligen' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      BY: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '06.01.2023', name: 'Heilige Drei K\u00f6nige' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '08.06.2023', name: 'Fronleichnam' },
        { date: '15.08.2023', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2023', name: 'Allerheiligen' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      SH: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
      HB: [
        { date: '01.01.2023', name: 'Neujahr' },
        { date: '07.04.2023', name: 'Karfreitag' },
        { date: '10.04.2023', name: 'Ostermontag' },
        { date: '01.05.2023', name: 'Maifeiertag' },
        { date: '18.05.2023', name: 'Christi Himmelfahrt' },
        { date: '29.05.2023', name: 'Pfingstmontag' },
        { date: '03.10.2023', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2023', name: 'Reformationstag' },
        { date: '25.12.2023', name: '1. Weihnachtstag' },
        { date: '26.12.2023', name: '2. Weihnachtstag' },
      ],
    },
    2024: {
      RP: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2024', name: 'Allerheiligen' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      SN: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '20.11.2024', name: 'Bu\u00df- und Bettag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      TH: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '20.09.2024', name: 'Weltkindertag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      HE: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      SL: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '15.08.2024', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2024', name: 'Allerheiligen' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      NI: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      BW: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '06.01.2024', name: 'Heilige Drei K\u00f6nige' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2024', name: 'Allerheiligen' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      BB: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      HH: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      MV: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      ST: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '06.01.2024', name: 'Heilige Drei K\u00f6nige' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      BE: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '08.03.2024', name: 'Frauentag' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      NW: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2024', name: 'Allerheiligen' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      BY: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '06.01.2024', name: 'Heilige Drei K\u00f6nige' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '30.05.2024', name: 'Fronleichnam' },
        { date: '15.08.2024', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2024', name: 'Allerheiligen' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      SH: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
      HB: [
        { date: '01.01.2024', name: 'Neujahr' },
        { date: '29.03.2024', name: 'Karfreitag' },
        { date: '01.04.2024', name: 'Ostermontag' },
        { date: '01.05.2024', name: 'Maifeiertag' },
        { date: '09.05.2024', name: 'Christi Himmelfahrt' },
        { date: '20.05.2024', name: 'Pfingstmontag' },
        { date: '03.10.2024', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2024', name: 'Reformationstag' },
        { date: '25.12.2024', name: '1. Weihnachtstag' },
        { date: '26.12.2024', name: '2. Weihnachtstag' },
      ],
    },
    2025: {
      RP: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2025', name: 'Allerheiligen' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      SN: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '19.11.2025', name: 'Bu\u00df- und Bettag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      TH: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '20.09.2025', name: 'Weltkindertag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      HE: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      SL: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '15.08.2025', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2025', name: 'Allerheiligen' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      NI: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      BW: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '06.01.2025', name: 'Heilige Drei K\u00f6nige' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2025', name: 'Allerheiligen' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      BB: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      HH: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      MV: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      ST: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '06.01.2025', name: 'Heilige Drei K\u00f6nige' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      BE: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '08.03.2025', name: 'Frauentag' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      NW: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2025', name: 'Allerheiligen' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      BY: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '06.01.2025', name: 'Heilige Drei K\u00f6nige' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '19.06.2025', name: 'Fronleichnam' },
        { date: '15.08.2025', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2025', name: 'Allerheiligen' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      SH: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
      HB: [
        { date: '01.01.2025', name: 'Neujahr' },
        { date: '18.04.2025', name: 'Karfreitag' },
        { date: '21.04.2025', name: 'Ostermontag' },
        { date: '01.05.2025', name: 'Maifeiertag' },
        { date: '29.05.2025', name: 'Christi Himmelfahrt' },
        { date: '09.06.2025', name: 'Pfingstmontag' },
        { date: '03.10.2025', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2025', name: 'Reformationstag' },
        { date: '25.12.2025', name: '1. Weihnachtstag' },
        { date: '26.12.2025', name: '2. Weihnachtstag' },
      ],
    },
    2026: {
      RP: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2026', name: 'Allerheiligen' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      SN: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '18.11.2026', name: 'Bu\u00df- und Bettag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      TH: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '20.09.2026', name: 'Weltkindertag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      HE: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      SL: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '15.08.2026', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2026', name: 'Allerheiligen' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      NI: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      BW: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '06.01.2026', name: 'Heilige Drei K\u00f6nige' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2026', name: 'Allerheiligen' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      BB: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      HH: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      MV: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      ST: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '06.01.2026', name: 'Heilige Drei K\u00f6nige' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      BE: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '08.03.2026', name: 'Frauentag' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      NW: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2026', name: 'Allerheiligen' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      BY: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '06.01.2026', name: 'Heilige Drei K\u00f6nige' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '04.06.2026', name: 'Fronleichnam' },
        { date: '15.08.2026', name: 'Mari\u00e4 Himmelfahrt' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '01.11.2026', name: 'Allerheiligen' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      SH: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
      HB: [
        { date: '01.01.2026', name: 'Neujahr' },
        { date: '03.04.2026', name: 'Karfreitag' },
        { date: '06.04.2026', name: 'Ostermontag' },
        { date: '01.05.2026', name: 'Maifeiertag' },
        { date: '14.05.2026', name: 'Christi Himmelfahrt' },
        { date: '25.05.2026', name: 'Pfingstmontag' },
        { date: '03.10.2026', name: 'Tag der Deutschen Einheit' },
        { date: '31.10.2026', name: 'Reformationstag' },
        { date: '25.12.2026', name: '1. Weihnachtstag' },
        { date: '26.12.2026', name: '2. Weihnachtstag' },
      ],
    },
  },
};
