import { differenceInMinutes } from 'date-fns';

import { useNotificationStore } from '@/features/notifications';
import { gql as gqlFn } from '@/graphql/__generated__';
import { useApolloClient } from '@/plugins/apollo';
import { useLoggingService } from '@/services/logging/composable';
import { UserInvitationResendType } from '@/utils/analyticsEvents/eventCategories/memberships';

/**
 * Users are only allowed to reinvite members once per session
 * so we can store it here.
 */
const resentInvitationMembershipIds: Ref<string[]> = ref([]);

/**
 * ID of reinvited memberships. Does not include ids of newly
 * created memberships.
 */
const reinvitedMembershipIds: Ref<string[]> = ref([]);

const resendUserInvitationMutation = gqlFn(/* GraphQL */ `
  mutation ResendUserInvitation($input: ResendUserInvitationInput!) {
    resendUserInvitation(input: $input) {
      lastInvitationSentAt
      user {
        id
        isActive
        firstName
        lastName
      }
    }
  }
`);

export const useResendMembershipInvitation = () => {
  const loggingService = useLoggingService();
  const notificationStore = useNotificationStore();

  const resendInvitation = (membershipId: string, membershipType: UserInvitationResendType) => {
    const client = useApolloClient();
    return client
      .mutate({
        mutation: resendUserInvitationMutation,
        variables: {
          input: { membership: membershipId },
        },
      })
      .then((result) => {
        const resendResult = result.data?.resendUserInvitation;
        if (resendResult) {
          resentInvitationMembershipIds.value = [
            ...resentInvitationMembershipIds.value,
            membershipId,
          ];

          reinvitedMembershipIds.value = [...reinvitedMembershipIds.value, membershipId];
        }

        const lastInvitedAt = resendResult?.lastInvitationSentAt
          ? new Date(resendResult.lastInvitationSentAt)
          : undefined;

        loggingService.trackEvent(
          new loggingService.AnalyticEventCategories.UserInvitationResend({
            type: membershipType,
            timeInMinutesSinceLastInvitation: lastInvitedAt
              ? differenceInMinutes(new Date(), lastInvitedAt)
              : undefined,
          }),
        );

        return resendResult;
      });
  };

  return {
    resendInvitation,
    canInviteAgain: (membershipId: string) => {
      return computed(() => !resentInvitationMembershipIds.value.includes(membershipId));
    },
    wasReinvited: (membershipId: string) => {
      return computed(() => reinvitedMembershipIds.value.includes(membershipId));
    },
    onMembershipCreated: (membership: { id: string }) => {
      resentInvitationMembershipIds.value = [...resentInvitationMembershipIds.value, membership.id];
    },
    showProjectInviteNotification: ({
      notificationTestId,
      userEmail,
    }: {
      notificationTestId?: string;
      userEmail: string;
    }) => {
      notificationStore.showCreateNotification({
        testId: notificationTestId,
        titleI18nKey: 'objects.projectMemberships.invitationEmailSentAgainTitle',
        bodyI18nKey: 'objects.projectMemberships.invitationEmailSentAgainSubtitle',
        bodyI18nKeyVariables: { email: userEmail },
      });
    },
  };
};
