import { Ref, ref } from 'vue';

import { buildAddress } from '@/helpers/address';
import { Address } from '@/interfaces/repositories/utility';
import { LocationService } from '@/interfaces/services';
import { LocationPrediction, LocationServiceQueryResult } from '@/interfaces/services/location';
import { GoogleMaps } from '@/plugins/googleMaps';
import { AvailableHolidayState, states } from '@/utils/holidayList';
import { statePostalCodeList } from '@/utils/statePostalCodes';

export class GoogleMapsLocationService implements LocationService {
  private sessionToken: google.maps.places.AutocompleteSessionToken | null = null;

  private autocompleteService: google.maps.places.AutocompleteService | null = null;

  private placesService: google.maps.places.PlacesService | null = null;

  public canUseLocation = ref(false);

  private addressToPlaceLookupCache: Map<string, string> = new Map();

  public constructor(private googleMaps: GoogleMaps) {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    await this.googleMaps.$gmapApiPromiseLazy();

    this.canUseLocation.value = true;

    /**
     * Places service requires google map element that we don't want to have,
     *  to hack this a non-visible div element will be added and used by the service
     * */
    const fakeMap = document.createElement('div');

    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.placesService = new google.maps.places.PlacesService(fakeMap);
  }

  public tryFetchState(postalCode: string): AvailableHolidayState | undefined {
    const numericPostalCode = parseInt(postalCode.trim(), 10);
    if (Number.isNaN(numericPostalCode)) return undefined;

    const entries = Object.entries(statePostalCodeList);

    for (const entry of entries) {
      const [stateName, statePostalCodes] = entry;
      for (let i = 0; i < statePostalCodes.length; i++) {
        const currentPostalCode = statePostalCodes[i];
        if (currentPostalCode === numericPostalCode) {
          return mapStateNameToHolidayState(stateName);
        } else if (currentPostalCode > numericPostalCode) {
          // sorted in ascending order, seeing larger code means it's not code of Bundesland
          break;
        }
      }
    }

    return undefined;
  }

  public usePredictions(): LocationServiceQueryResult<string, LocationPrediction[]> {
    const predictions = ref([]) as Ref<LocationPrediction[]>;
    const loading = ref(false);

    return {
      result: predictions,
      refetch: (searchTerm) => {
        loading.value = true;
        this.getPredictions(searchTerm, predictions, loading);
      },
      loading,
    };
  }

  public usePlace(): LocationServiceQueryResult<string, Address> {
    const address = ref(buildAddress());
    const loading = ref(false);

    return {
      result: address,
      refetch: (id) => {
        loading.value = true;
        this.getPlace(id, address, loading);
      },
      loading,
    };
  }

  public useState(): LocationServiceQueryResult<string, string> {
    const state = ref('');
    const loading = ref(false);

    return {
      result: state,
      refetch: (address) => {
        this.getState(address, state, loading);
      },
      loading,
    };
  }

  private getPredictions(
    searchTerm: string,
    result: Ref<LocationPrediction[]>,
    loading: Ref<boolean>,
  ): void {
    if (!this.autocompleteService) return;

    // fetch place predictions depending on search term
    this.autocompleteService.getPlacePredictions(
      {
        input: searchTerm,
        sessionToken: this.sessionToken ?? undefined,
        types: [],
      },
      (_predictions, status) => {
        loading.value = false;
        if (status !== google.maps.places.PlacesServiceStatus.OK) return;
        result.value = (_predictions ?? []).map((prediction) => ({
          placeId: prediction.place_id,
          description: prediction.description,
        }));
      },
    );
  }

  private getPlace(id: string, result: Ref<Address>, loading: Ref<boolean>): void {
    if (!this.placesService || !id) return;

    // fetch address details of google places id
    this.placesService.getDetails(
      {
        placeId: id,
        fields: ['address_components'],
      },
      (details, status) => {
        loading.value = false;
        if (status !== google.maps.places.PlacesServiceStatus.OK) return;

        // extract address data from return object
        const components = details?.address_components ?? [];
        const postalCode = components.find((data) => data.types.includes('postal_code')) ?? null;
        const street = components.find((data) => data.types.includes('route')) ?? null;
        const streetNumber =
          components.find((data) => data.types.includes('street_number')) ?? null;
        const city = components.find((data) => data.types.includes('locality')) ?? null;
        const country = components.find((data) => data.types.includes('country')) ?? null;

        // format data to required variable type
        result.value = {
          ...result.value,
          postalCode: postalCode?.long_name ?? '',
          street: (street ? `${street.long_name} ${streetNumber?.long_name ?? ''}` : '').trim(),
          city: city?.long_name ?? '',
          country: country?.short_name ?? '',
        };
      },
    );
  }

  private getState(address: string, result: Ref<string>, loading: Ref<boolean>): void {
    if (!this.placesService) return;

    const cached = this.addressToPlaceLookupCache.get(address);
    if (cached) {
      result.value = cached;
      return;
    }

    loading.value = true;
    // fetch possible places from address
    this.placesService.findPlaceFromQuery(
      {
        query: address,
        fields: ['place_id'],
      },
      (possiblePlaces, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          loading.value = false;
          return;
        }
        const placeId = possiblePlaces?.length ? (possiblePlaces[0].place_id ?? '') : '';
        // fetch place details
        this.placesService?.getDetails(
          {
            placeId,
            fields: ['address_component'],
          },
          (details, _status) => {
            loading.value = false;
            if (_status !== google.maps.places.PlacesServiceStatus.OK) return;

            const state =
              details?.address_components?.find((component) =>
                component.types.includes('administrative_area_level_1'),
              )?.long_name ?? '';

            this.addressToPlaceLookupCache.set(address, state);
            result.value = state;
          },
        );
      },
    );
  }
}

function mapStateNameToHolidayState(stateName: string): AvailableHolidayState | undefined {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const stateEntry = Object.entries(states.DE).find(([_state, name]) => name === stateName);

  return stateEntry ? (stateEntry[0] as unknown as AvailableHolidayState) : undefined;
}
