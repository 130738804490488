import { ChangelogOpened } from './changelog';
import {
  TenantMemberAddedEvent,
  TenantMemberDeletedEvent,
  UserInvitationResend,
} from './memberships';
import {
  OrderCreatedEvent,
  OrderDocumentsUploadedEvent,
  OrderDocumentViewedEvent,
  OrderOpenedEvent,
  OrderPhotoOpenedEvent,
  OrderPhotosDownloadedEvent,
  OrderPhotosUploadedEvent,
  OrdersCopiedEvent,
  OrdersCutEvent,
  OrdersDeletedEvent,
  OrdersEditedEvent,
  OrdersMovedOutsideWorkingTimesEvent,
  OrdersPastedEvent,
  OrderStatusReported,
  WorkScheduleExceptionPopupClosedEvent,
} from './orders';
import {
  ConstructionBreakCreatedEvent,
  ConstructionBreakOpenedEvent,
  ConstructionBreaksDeletedEvent,
  ConstructionBreaksEditedEvent,
} from './pause';
import {
  ProjectAlternativeActivated,
  ProjectAlternativeArchived,
  ProjectAlternativeCreated,
  ProjectAlternativeEdited,
  ProjectAlternativeModeEntered,
  ProjectAlternativeModeLeft,
  ProjectAlternativeSwitched,
} from './projectVersions';
import { PWAInstalled } from './pwa';
import {
  ScheduleEntitiesDeleted,
  ScheduleEntitiesEdited,
  ScheduleEntitiesSelected,
  ScheduleJumpToTodayClickedEvent,
  ScheduleOrderViewToggledEvent,
  ScheduleRedoEvent,
  ScheduleUndoEvent,
} from './schedule';
import { TaskStatusReportedEvent } from './tasks';
import {
  TradeSequenceCreatedEvent,
  TradeSequenceDeletedEvent,
  TradeSequenceDuplicatedEvent,
  TradeSequenceEditedEvent,
  TradeSequenceInsertedEvent,
  TradeSequenceUpdateConflictsDetected,
} from './tradeSequence';
import {
  SignInEvent,
  SignOutEvent,
  SignUpEvent,
  UserProfileEditedEvent,
  UserSSOProviderConnectedEvent,
} from './user';
import {
  VersionHistoryApplied,
  VersionHistoryHelpClicked,
  VersionHistoryManuallySaved,
  VersionHistoryOpened,
  VersionHistoryViewed,
} from './versioning';
import { WorkingCalendarCreatedEvent, WorkingCalendarEditedEvent } from './workCalendars';

/** Export all possible events as constant */
export const EventCategories = {
  ConstructionBreakCreatedEvent,
  ConstructionBreakOpenedEvent,
  ConstructionBreaksDeletedEvent,
  ConstructionBreaksEditedEvent,
  ChangelogOpened,
  OrderCreatedEvent,
  OrderOpenedEvent,
  OrdersCopiedEvent,
  OrdersCutEvent,
  OrdersDeletedEvent,
  OrdersEditedEvent,
  OrdersPastedEvent,
  OrderStatusReported,
  OrderPhotosDownloadedEvent,
  OrderPhotosUploadedEvent,
  OrderDocumentViewedEvent,
  OrderPhotoOpenedEvent,
  OrderDocumentsUploadedEvent,
  OrdersMovedOutsideWorkingTimesEvent,
  WorkScheduleExceptionPopupClosedEvent,
  ScheduleOrderViewToggledEvent,
  ScheduleJumpToTodayClickedEvent,
  ScheduleRedoEvent,
  ScheduleUndoEvent,
  ScheduleEntitiesDeleted,
  ScheduleEntitiesEdited,
  ScheduleEntitiesSelected,
  ProjectAlternativeActivated,
  ProjectAlternativeArchived,
  ProjectAlternativeCreated,
  ProjectAlternativeEdited,
  ProjectAlternativeModeEntered,
  ProjectAlternativeModeLeft,
  ProjectAlternativeSwitched,
  PWAInstalled,
  SignInEvent,
  SignOutEvent,
  SignUpEvent,
  UserSSOProviderConnectedEvent,
  UserProfileEditedEvent,
  TaskStatusReportedEvent,
  TenantMemberAddedEvent,
  TenantMemberDeletedEvent,
  UserInvitationResend,
  TradeSequenceCreatedEvent,
  TradeSequenceDeletedEvent,
  TradeSequenceDuplicatedEvent,
  TradeSequenceEditedEvent,
  TradeSequenceInsertedEvent,
  TradeSequenceUpdateConflictsDetected,
  VersionHistoryOpened,
  VersionHistoryViewed,
  VersionHistoryApplied,
  VersionHistoryHelpClicked,
  VersionHistoryManuallySaved,
  WorkingCalendarCreatedEvent,
  WorkingCalendarEditedEvent,
};
