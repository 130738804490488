import {
  Callback,
  ProjectContributorGroupChangePayload,
  ProjectSubscriptionCallback,
  ProjectSubscriptionEvent,
  ProjectTradeChangePayload,
  ProjectTradeReplacementPayload,
  ProjectUnsubscribeEvent,
  RTCSocketActions as GeneratedRTCSocketActions,
} from 'events.schema';
import { debounce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

import { useIsOnline } from '@/common/composables/useIsOnline';
import { ProjectUser } from '@/helpers/users';
import { getRandomId } from '@/helpers/utils/strings';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { ConsoleLoggingService } from '@/services';
import { IS_PROD_ENV, IS_TEST_ENV } from '@/utils/config';

import { useLiveUsers } from '../components/liveUsers/useLiveUsers';
import {
  RTC_EVENT_EMIT_TIMEOUT,
  RTC_LIVE_USERS_DEBOUNCE_TIME,
  RTC_REPLAY_UNACKNOWLEDGED_EVENTS_INTERVAL,
} from '../const';
import { LocalProjectChangeEvent, LocalProjectChangeEventTemplate } from '../types';
import { DefaultRTCMessageParser, RTCMessageParser } from './messageParser';
import {
  ExternalProjectDataChangeListener,
  ProjectChangeEventListener,
  RTCClientConnectionState,
  RTCClientResponseStatus,
  RTCSocketActions,
} from './types';
import { getConnectionWaitInterval, getJitteredTimeout } from './utils';

export class RTCClient {
  private socket: Socket;

  private clientId: string | undefined;

  private projectId: string | undefined;

  private stateId: number | undefined;

  private clientStateId: number = 0;

  /**
   * Stores local clientStateIds of projects.
   */
  private projectClientStateIds: Record<string, number> = {};

  private connectionState = ref(RTCClientConnectionState.IDLE);

  private connectionStateSubject = new BehaviorSubject<RTCClientConnectionState>(
    RTCClientConnectionState.IDLE,
  );

  private projectChangeEventListeners: ProjectChangeEventListener[] = [];

  private externalProjectDataChangeListeners: ExternalProjectDataChangeListener[] = [];

  private connectionAttempts: number = 0;

  private connectionTimeoutId: number | undefined;

  private connectionWaitInterval: Ref<number> = ref(0);

  private unacknowledgedEvents: Map<
    string,
    { event: LocalProjectChangeEvent; onPublished?: () => void }
  > = new Map();

  private unacknowledgedStateIds: number[] = [];

  private eventTimestamps: Map<string, number> = new Map();

  private connectedBefore = false;

  private transport: Ref<'websocket' | 'polling' | null> = ref(null);

  private replayEventsInterval: number | undefined;

  private receivedMessageIds: string[] = [];

  public constructor(
    private authenticationService: AuthenticationService,
    private endpoint: string,
    private messageParser: RTCMessageParser = new DefaultRTCMessageParser(),
    private loggingService: LoggingService = new ConsoleLoggingService(),
  ) {
    this.clientId = getRandomId();
    if (IS_TEST_ENV) {
      (window as any).clientId = this.clientId;
    }
    this.socket = this.createSocket();
    this.setupOnlineListener();
    this.setupSocketListeners();
    this.setupStatusSubscription();
  }

  private setupOnlineListener(): void {
    watch(useIsOnline(), (isOnline) => {
      if (isOnline) {
        this.startConnectionAttempts();
      } else {
        this.stopConnectionAttempts();
      }
    });
  }

  private setupStatusSubscription(): void {
    this.connectionStateSubject.subscribe((state) => {
      this.connectionState.value = state;
      if (state === RTCClientConnectionState.CONNECTED) {
        this.transport.value = this.socket.io.engine.transport.name as 'websocket' | 'polling';
        this.stopConnectionAttempts();
        this.attemptProjectResubscription();
      } else if (
        state === RTCClientConnectionState.DISCONNECTED ||
        state === RTCClientConnectionState.ERROR
      ) {
        this.startConnectionAttempts();
        this.transport.value = null;
        window.clearInterval(this.replayEventsInterval);
      }
    });
  }

  private clearConnectionTimeout(): void {
    if (this.connectionTimeoutId) {
      window.clearTimeout(this.connectionTimeoutId);
      this.connectionTimeoutId = undefined;
    }
  }

  private stopConnectionAttempts(): void {
    this.clearConnectionTimeout();
    this.connectionWaitInterval.value = 0;
    this.connectionAttempts = 0;
  }

  private createSocket(): Socket {
    return io(this.endpoint, {
      autoConnect: false,
      reconnection: false,
      query: {
        clientId: this.clientId,
      },
      secure: true,
      // Enable for load balancer cookies
      withCredentials: true,
      transports: ['websocket', 'polling'],
      auth: async (cb) => {
        // "auth" is called when executing socket.connect()

        const token = await this.authenticationService.getIdToken();
        if (!token)
          throw new Error('Auth token not found. Cannot continue with socket connection.');

        cb({ token: `JWT ${token}` });
      },
    });
  }

  public connect(): void {
    this.startConnectionAttempts();
  }

  private setupSocketListeners(): void {
    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Trades,
      (payload: ProjectTradeChangePayload) => {
        this.log('Trades changed', payload);
        const previousTimestamp = this.eventTimestamps.get(
          GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Trades,
        );
        if (!previousTimestamp || payload.timestamp > previousTimestamp) {
          this.externalProjectDataChangeListeners.forEach((listener) =>
            listener.callback({ name: 'TradeChange', data: payload }),
          );
          this.eventTimestamps.set(
            GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Trades,
            payload.timestamp,
          );
        }
      },
    );

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.TradeReplacement,
      (payload: ProjectTradeReplacementPayload) => {
        this.log('Trade was replaced', payload);

        this.externalProjectDataChangeListeners.forEach((listener) =>
          listener.callback({ name: 'TradeReplacement', data: payload }),
        );
      },
    );

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.LiveUsersChanged,
      debounce(
        (payload: { users: ProjectUser[] }) => {
          const { setLiveUsers } = useLiveUsers();
          setLiveUsers(payload?.users);
        },
        RTC_LIVE_USERS_DEBOUNCE_TIME,
        { leading: true },
      ),
    );

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.ContributorGroups,
      (payload: ProjectContributorGroupChangePayload) => {
        this.log('Contributor groups changed', payload);

        const previousTimestamp = this.eventTimestamps.get(
          GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.ContributorGroups,
        );
        if (!previousTimestamp || payload.timestamp > previousTimestamp) {
          this.externalProjectDataChangeListeners.forEach((listener) =>
            listener.callback({ name: 'ProjectContributorGroupChange', data: payload }),
          );
          this.eventTimestamps.set(
            GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges
              .ContributorGroups,
            payload.timestamp,
          );
        }
      },
    );

    this.socket.on(RTCSocketActions.ServerToClientActions.Connect, () => {
      this.connectedBefore = true;
      this.log('Connected to RTC, transport:', this.socket.io.engine.transport.name);

      this.connectionStateSubject.next(RTCClientConnectionState.CONNECTED);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.ConnectError, (error) => {
      // Code set by backend
      // @ts-expect-error-next-line
      const errorCode = error?.data?.code;
      const isNetworkError = errorCode !== 'WS_CONNECTION_ERROR';
      if (!this.connectedBefore && isNetworkError) {
        // https://socket.io/docs/v3/client-initialization/#low-level-engine-options
        // Revert to classic upgrade, websocket upgrade will be attempted after connection was established
        this.socket.io.opts.transports = ['polling', 'websocket'];
        // always log this to console so support can easily verify behavior for customers

        this.log('Change to polling approach');
      }
      this.connectionStateSubject.next(RTCClientConnectionState.ERROR);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.Disconnect, () => {
      this.log('Disconnected from websocket');

      this.connectionStateSubject.next(RTCClientConnectionState.DISCONNECTED);
    });

    this.socket.on('force_disconnect', () => {
      // NOTE: Only used for test purposes to simulate server disconnect
      if (!IS_TEST_ENV) {
        return;
      }
      this.socket.disconnect();
      this.connectionStateSubject.next(RTCClientConnectionState.ERROR);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.ProjectChanged, (payload) => {
      if (!this.projectId) return;
      const event = this.messageParser.parseIncoming(payload);
      this.log('Incoming event', event);

      if (this.receivedMessageIds.includes(event.messageId)) {
        this.loggingService.warn(`Received duplicate message: ${event.messageId}`);
        return;
      }

      this.receivedMessageIds.push(event.messageId);

      const isOwn = event.clientId === this.clientId;
      if (event.stateId != null) {
        if (event.status === RTCClientResponseStatus.SUCCESS) {
          if (this.stateId !== undefined && event.stateId <= this.stateId && !IS_TEST_ENV) {
            this.handleUnacknowledgedStateId(event.stateId, event.messageId);
          } else {
            this.stateId = event.stateId;
          }
        }
      }

      // Project change event emits are timed out, however, it may happen that
      // the server still acknowledges the event, even after the timeout, which is why we need to also
      // remove the event from the unacknowledged events list here.
      const unacknowledgedEvent = this.unacknowledgedEvents.get(event.messageId);
      if (unacknowledgedEvent) {
        unacknowledgedEvent.onPublished?.();
        this.unacknowledgedEvents.delete(event.messageId);
      }

      this.projectChangeEventListeners.forEach((listener) => listener.callback(event, isOwn));
    });
  }

  private attemptProjectResubscription(): void {
    if (this.projectId) {
      this.subscribeToProject(this.projectId).catch((error) => {
        this.loggingService.error(error, { code: 'RTCClientResubscribeError' });
        this.socket.disconnect();
      });
    }
  }

  private async startConnectionAttempts(): Promise<void> {
    const isOnline = useIsOnline();

    if (
      isOnline.value &&
      this.connectionState.value !== RTCClientConnectionState.CONNECTED &&
      this.connectionState.value !== RTCClientConnectionState.CONNECTING
    ) {
      this.log('Trying to connect...');
      await this.authenticationService.waitForAuthentication();

      const waitInterval = getConnectionWaitInterval(this.connectionAttempts);
      this.connectionWaitInterval.value = waitInterval;

      this.clearConnectionTimeout();
      this.connectionTimeoutId = window.setTimeout(() => {
        this.connectionAttempts++;

        this.socket.connect();
      }, waitInterval);
    }
  }

  public registerProjectChangeEventListener(listener: ProjectChangeEventListener) {
    if (this.projectChangeEventListeners.some((l) => l.id === listener.id)) return;
    this.projectChangeEventListeners.push(listener);
  }

  public registerExternalProjectDataChangeListener(listener: ExternalProjectDataChangeListener) {
    if (this.externalProjectDataChangeListeners.some((l) => l.id === listener.id)) return;
    this.externalProjectDataChangeListeners.push(listener);
  }

  public prepareProjectChangeEventForPublishing(
    template: LocalProjectChangeEventTemplate,
  ): LocalProjectChangeEvent {
    if (!this.projectId) throw new Error('Cannot prepare change event without defined project id.');

    const eventClientStateId = this.clientStateId;
    this.clientStateId += 1;
    return {
      ...template,
      clientTimestampMs: new Date().getTime(),
      stateId: this.stateId ?? 0,
      clientStateId: eventClientStateId,
      projectId: this.projectId,
    };
  }

  public publishProjectChangeEvent(
    event: LocalProjectChangeEvent,
    onPublished?: () => void,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!useIsOnline().value) {
        if (!this.unacknowledgedEvents.has(event.messageId)) {
          this.unacknowledgedEvents.set(event.messageId, { event, onPublished });
        }
        resolve();
        return;
      }

      const onProjectChange = (timeoutError: Error | undefined, response: Callback) => {
        if (timeoutError) {
          this.log('Event Timed Out', event);
          this.unacknowledgedEvents.set(event.messageId, { event, onPublished });
          resolve();
          return;
        }

        this.unacknowledgedEvents.delete(event.messageId);
        if (response.status === RTCClientResponseStatus.ERROR) {
          this.loggingService.error(new Error(response.error.reason), {
            code: response.error.errorCode,
          });
          reject(response.error);
        } else if (response.status === RTCClientResponseStatus.SUCCESS) {
          resolve();
          onPublished?.();
        }
      };

      this.log('Outgoing Event', event);

      this.socket
        .timeout(RTC_EVENT_EMIT_TIMEOUT)
        .emit(
          RTCSocketActions.ClientToServerActions.ProjectChange,
          this.messageParser.parseOutgoing(event),
          onProjectChange,
        );
    });
  }

  public unsubscribeFromProject(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.projectId) {
        resolve();
        return;
      }

      this.projectClientStateIds[this.projectId] = this.clientStateId;

      const payload: ProjectUnsubscribeEvent = {
        projectId: this.projectId,
      };

      const onUnsubscribe = (response: Callback) => {
        if (response.status === RTCClientResponseStatus.ERROR) {
          this.loggingService.error(new Error(response.error.reason), {
            code: response.error.errorCode,
          });
          reject(response.error);
        } else if (response.status === RTCClientResponseStatus.SUCCESS) {
          this.log('Unsubscribed from project', response);

          this.resetProjectState();
          resolve();
        }
      };

      this.socket.emit(
        RTCSocketActions.ClientToServerActions.UnsubscribeProject,
        payload,
        onUnsubscribe,
      );
    });
  }

  public subscribeToProject(projectId: string): Promise<void> {
    if (!projectId) throw new Error('Cannot subscribe to project without a project ID.');

    const clientStateId = this.projectClientStateIds[projectId] ?? 0;
    this.clientStateId = clientStateId;

    this.loggingService.info(
      `Trying to subscribe: clientStateId=${this.clientStateId}, stateId=${this.stateId}`,
    );

    return new Promise((resolve, reject) => {
      const clientState =
        this.stateId === undefined
          ? null
          : {
              earliestUnprocessedStateId: this.clientStateId!,
              lastReceivedStateId: this.stateId!,
            };

      const payload: ProjectSubscriptionEvent = {
        projectId,
        clientState,
      };

      const onSubscribe = async (response: ProjectSubscriptionCallback) => {
        if (response.status === RTCClientResponseStatus.ERROR) {
          reject(response.error);
        } else if (response.status === RTCClientResponseStatus.SUCCESS) {
          this.log('Subscribed to project', response);

          this.setUnacknowledgedStateIds(response.stateId);
          this.stateId = response.stateId;
          this.projectId = projectId;

          this.replayUnacknowledgedEvents();
          this.replayEventsInterval = window.setInterval(() => {
            this.replayUnacknowledgedEvents();
          }, getJitteredTimeout(RTC_REPLAY_UNACKNOWLEDGED_EVENTS_INTERVAL));

          resolve();
        }
      };

      this.socket.emit(
        RTCSocketActions.ClientToServerActions.SubscribeProject,
        payload,
        onSubscribe,
      );
    });
  }

  // Remove from the list if state ID fills expected gap, otherwise log a mismatch error.
  private handleUnacknowledgedStateId(stateId: number, messageId: string): void {
    if (this.unacknowledgedStateIds.includes(stateId)) {
      this.unacknowledgedStateIds = this.unacknowledgedStateIds.filter(
        (id: number) => id !== stateId,
      );
    } else {
      this.loggingService.error(
        new Error(
          `Outdated stateId for event ${messageId} (Received: ${stateId}, Latest: ${this.stateId})`,
        ),
        {
          code: 'RTCClientStateIdError',
        },
      );
    }
  }

  /**
   * Example cases:
   * Case 1: stateId = 5, unacknowledgedStateIds = []
   * Case 2: stateId = 6, unacknowledgedStateIds = [6]
   * Case 3: stateId = 7, unacknowledgedStateIds = [6, 7]
   */
  private setUnacknowledgedStateIds(stateId: number) {
    if (typeof this.stateId === 'number' && this.stateId < stateId) {
      for (let i = this.stateId + 1; i <= stateId; i++) {
        this.unacknowledgedStateIds.push(i);
      }
    }
  }

  private async replayUnacknowledgedEvents(): Promise<void> {
    const unacknowledgedEvents = Array.from(this.unacknowledgedEvents.values());
    for (let i = 0; i < unacknowledgedEvents.length; i++) {
      const unacknowledgedEvent = unacknowledgedEvents[i];
      await this.publishProjectChangeEvent(
        unacknowledgedEvent.event,
        unacknowledgedEvent.onPublished,
      );
    }
  }

  private resetProjectState(): void {
    this.projectId = '';
    this.stateId = undefined;
    this.clientStateId = 0;
    this.projectChangeEventListeners = [];
    this.unacknowledgedEvents = new Map();
    this.unacknowledgedStateIds = [];
    this.receivedMessageIds = [];
    useLiveUsers().setLiveUsers([]);
  }

  private log(...args: unknown[]) {
    if (!IS_PROD_ENV) {
      // eslint-disable-next-line no-console
      console.log('[RTCClient]', ...args);
    }
  }

  public get isConnected(): ComputedRef<boolean> {
    return computed(() => this.connectionState.value === RTCClientConnectionState.CONNECTED);
  }

  public get isPollingTransport(): ComputedRef<boolean> {
    return computed(() => this.transport.value === 'polling');
  }

  public get connectionIntervalMs(): ComputedRef<number> {
    return computed(() => this.connectionWaitInterval.value);
  }

  public get unacknowledgedEventsList(): LocalProjectChangeEvent[] {
    return Array.from(this.unacknowledgedEvents.values()).map((e) => e.event);
  }

  public get hasUnacknowledgedEvents(): boolean {
    return this.unacknowledgedEvents.size > 0;
  }

  public getClientId(): string {
    return this.clientId ?? '';
  }

  public disconnect(): void {
    this.socket.disconnect();
  }
}
