import { AppApolloClient } from '@/interfaces/graphql';
import {
  AttachmentRepository,
  MembershipRepository,
  TenantRepository,
  UserRepository,
} from '@/interfaces/repositories';
import { ContainerModule, IoCContainer } from '@/ioc/framework/container';
import {
  GraphQLAttachmentRepository,
  GraphQLMembershipRepository,
  GraphQLTenantRepository,
  GraphQLUserRepository,
} from '@/repositories';

export function registerMembershipRepository(
  container: IoCContainer,
  client: AppApolloClient,
  disableCache = false,
): MembershipRepository {
  const repo = new GraphQLMembershipRepository(client, disableCache);
  container.register<MembershipRepository>('MembershipRepository', () => repo);
  return repo;
}

class RepositoryModule implements ContainerModule {
  public onCreate(container: IoCContainer, client: AppApolloClient): void {
    container.register<AttachmentRepository>('AttachmentRepository', () => {
      return new GraphQLAttachmentRepository();
    });

    container.register<TenantRepository>('TenantRepository', () => {
      return new GraphQLTenantRepository(client);
    });

    container.register<UserRepository>('UserRepository', () => {
      return new GraphQLUserRepository(client);
    });
  }
}

export default new RepositoryModule();
