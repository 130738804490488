/**
 * Hard-coded list of place codes for German states.
 * To reproduce:
 * 1. Go to https://public.opendatasoft.com/explore/dataset/georef-germany-postleitzahl/export/ and export whole dataset as json
 * 2. Call `node ./scripts/postalCodeTransform.cjs <filepath>` to update this file's content with <filepath>
 *    being the absolute path to the downloaded export file.
 */
export const statePostalCodeList = {
  'Nordrhein-Westfalen': [
    32049, 32051, 32052, 32105, 32107, 32108, 32120, 32130, 32139, 32257, 32278, 32289, 32312,
    32339, 32351, 32361, 32369, 32423, 32425, 32427, 32429, 32457, 32469, 32479, 32545, 32547,
    32549, 32584, 32602, 32609, 32657, 32676, 32683, 32689, 32694, 32699, 32756, 32758, 32760,
    32791, 32805, 32816, 32825, 32832, 32839, 33014, 33034, 33039, 33098, 33100, 33102, 33104,
    33106, 33129, 33142, 33154, 33161, 33165, 33175, 33178, 33181, 33184, 33189, 33330, 33330,
    33332, 33333, 33334, 33335, 33378, 33397, 33415, 33428, 33442, 33449, 33602, 33604, 33605,
    33607, 33609, 33609, 33611, 33613, 33615, 33617, 33619, 33647, 33649, 33659, 33689, 33699,
    33719, 33729, 33739, 33758, 33775, 33790, 33803, 33813, 33818, 33824, 33829, 34414, 34431,
    34434, 34439, 37671, 37688, 37696, 40210, 40211, 40211, 40212, 40213, 40215, 40217, 40219,
    40221, 40223, 40225, 40227, 40229, 40231, 40233, 40235, 40237, 40239, 40468, 40470, 40472,
    40472, 40474, 40476, 40477, 40479, 40489, 40545, 40547, 40549, 40589, 40591, 40593, 40595,
    40597, 40599, 40625, 40627, 40629, 40667, 40668, 40670, 40699, 40721, 40721, 40723, 40724,
    40764, 40789, 40822, 40878, 40880, 40882, 40883, 40885, 41061, 41061, 41061, 41063, 41063,
    41065, 41066, 41068, 41069, 41069, 41169, 41179, 41189, 41199, 41236, 41238, 41239, 41334,
    41352, 41363, 41366, 41372, 41379, 41460, 41462, 41464, 41466, 41468, 41469, 41470, 41472,
    41515, 41516, 41517, 41539, 41540, 41541, 41542, 41564, 41569, 41747, 41747, 41747, 41748,
    41748, 41748, 41749, 41751, 41812, 41836, 41844, 41849, 42103, 42105, 42107, 42109, 42111,
    42113, 42115, 42117, 42119, 42275, 42277, 42279, 42281, 42283, 42285, 42287, 42289, 42327,
    42329, 42349, 42369, 42389, 42399, 42477, 42489, 42499, 42549, 42551, 42553, 42555, 42579,
    42651, 42653, 42655, 42657, 42659, 42697, 42699, 42719, 42781, 42799, 42853, 42855, 42857,
    42859, 42897, 42899, 42929, 44135, 44137, 44139, 44141, 44143, 44145, 44147, 44149, 44225,
    44227, 44229, 44263, 44265, 44267, 44269, 44287, 44289, 44309, 44319, 44328, 44329, 44339,
    44357, 44359, 44369, 44379, 44388, 44532, 44534, 44536, 44575, 44577, 44577, 44577, 44579,
    44581, 44623, 44623, 44623, 44625, 44627, 44628, 44629, 44649, 44651, 44652, 44653, 44787,
    44789, 44791, 44793, 44795, 44797, 44799, 44799, 44801, 44801, 44803, 44805, 44807, 44809,
    44866, 44867, 44867, 44869, 44879, 44892, 44894, 45127, 45128, 45130, 45131, 45133, 45134,
    45136, 45138, 45139, 45141, 45143, 45144, 45145, 45147, 45149, 45219, 45239, 45257, 45259,
    45276, 45277, 45279, 45289, 45307, 45309, 45326, 45327, 45329, 45355, 45356, 45357, 45359,
    45468, 45470, 45472, 45473, 45475, 45476, 45478, 45479, 45481, 45525, 45527, 45529, 45549,
    45657, 45659, 45661, 45663, 45665, 45699, 45701, 45711, 45721, 45731, 45739, 45768, 45770,
    45772, 45879, 45881, 45883, 45884, 45886, 45888, 45889, 45891, 45892, 45894, 45896, 45897,
    45897, 45899, 45964, 45966, 45968, 46045, 46047, 46049, 46117, 46119, 46145, 46147, 46149,
    46236, 46238, 46240, 46242, 46244, 46282, 46284, 46286, 46325, 46342, 46348, 46354, 46359,
    46395, 46397, 46399, 46414, 46419, 46446, 46459, 46483, 46485, 46487, 46499, 46509, 46514,
    46519, 46535, 46537, 46539, 46562, 46569, 47051, 47053, 47055, 47057, 47058, 47059, 47119,
    47137, 47138, 47139, 47166, 47167, 47169, 47178, 47179, 47198, 47199, 47226, 47228, 47229,
    47239, 47249, 47259, 47269, 47279, 47441, 47443, 47445, 47447, 47475, 47495, 47506, 47509,
    47533, 47546, 47551, 47559, 47574, 47589, 47608, 47623, 47624, 47625, 47626, 47627, 47638,
    47647, 47652, 47661, 47665, 47669, 47798, 47799, 47800, 47802, 47803, 47804, 47805, 47807,
    47809, 47829, 47839, 47877, 47906, 47918, 47929, 48143, 48145, 48147, 48149, 48151, 48153,
    48155, 48157, 48159, 48161, 48163, 48165, 48167, 48231, 48249, 48268, 48282, 48291, 48301,
    48308, 48317, 48324, 48329, 48336, 48341, 48346, 48351, 48356, 48361, 48366, 48369, 48429,
    48431, 48432, 48432, 48477, 48485, 48493, 48496, 48565, 48599, 48607, 48612, 48619, 48624,
    48629, 48653, 48683, 48691, 48703, 48712, 48720, 48727, 48734, 48739, 49477, 49479, 49492,
    49497, 49504, 49509, 49525, 49536, 49545, 49549, 50126, 50127, 50129, 50169, 50170, 50170,
    50171, 50181, 50189, 50226, 50259, 50321, 50354, 50374, 50389, 50667, 50667, 50668, 50670,
    50672, 50674, 50676, 50677, 50678, 50679, 50733, 50735, 50735, 50737, 50739, 50765, 50767,
    50769, 50823, 50825, 50827, 50829, 50858, 50859, 50931, 50933, 50935, 50937, 50939, 50939,
    50968, 50969, 50996, 50997, 50999, 51061, 51063, 51065, 51067, 51069, 51103, 51105, 51107,
    51109, 51143, 51145, 51147, 51149, 51371, 51373, 51375, 51377, 51379, 51381, 51399, 51427,
    51429, 51465, 51467, 51467, 51469, 51491, 51503, 51515, 51519, 51545, 51570, 51580, 51588,
    51597, 51643, 51645, 51647, 51674, 51688, 51702, 51709, 51766, 51789, 52062, 52064, 52066,
    52068, 52070, 52072, 52074, 52076, 52078, 52080, 52134, 52146, 52152, 52152, 52156, 52156,
    52156, 52156, 52159, 52159, 52159, 52222, 52222, 52223, 52224, 52249, 52349, 52351, 52353,
    52355, 52372, 52379, 52382, 52385, 52388, 52391, 52393, 52396, 52399, 52428, 52441, 52445,
    52457, 52459, 52477, 52499, 52511, 52525, 52531, 52538, 53111, 53113, 53115, 53117, 53119,
    53121, 53123, 53125, 53127, 53129, 53173, 53175, 53177, 53179, 53225, 53227, 53229, 53332,
    53340, 53343, 53347, 53359, 53604, 53639, 53721, 53757, 53773, 53783, 53797, 53804, 53809,
    53819, 53840, 53842, 53844, 53859, 53879, 53879, 53881, 53894, 53902, 53909, 53913, 53919,
    53925, 53937, 53940, 53945, 53947, 53949, 57072, 57074, 57076, 57078, 57080, 57223, 57234,
    57250, 57258, 57271, 57290, 57299, 57319, 57334, 57339, 57368, 57392, 57399, 57413, 57439,
    57462, 57482, 57489, 58089, 58089, 58091, 58093, 58093, 58095, 58095, 58097, 58099, 58099,
    58119, 58135, 58239, 58256, 58285, 58300, 58313, 58332, 58339, 58452, 58453, 58454, 58455,
    58456, 58507, 58509, 58511, 58513, 58515, 58540, 58553, 58566, 58579, 58636, 58638, 58640,
    58642, 58644, 58675, 58706, 58708, 58710, 58730, 58739, 58762, 58769, 58791, 58802, 58809,
    58840, 58849, 59063, 59065, 59067, 59069, 59071, 59073, 59075, 59077, 59174, 59192, 59199,
    59227, 59229, 59269, 59302, 59320, 59329, 59348, 59368, 59379, 59387, 59394, 59399, 59423,
    59425, 59425, 59427, 59439, 59457, 59469, 59494, 59505, 59510, 59514, 59519, 59555, 59556,
    59557, 59558, 59581, 59590, 59597, 59602, 59609, 59755, 59757, 59759, 59821, 59823, 59846,
    59872, 59889, 59909, 59929, 59939, 59955, 59964, 59969,
  ],
  'Rheinland-Pfalz': [
    51598, 53424, 53426, 53474, 53489, 53498, 53501, 53505, 53506, 53507, 53508, 53518, 53520,
    53520, 53520, 53520, 53520, 53520, 53533, 53534, 53539, 53545, 53547, 53547, 53557, 53560,
    53562, 53567, 53572, 53577, 53578, 53579, 53619, 54290, 54292, 54293, 54294, 54295, 54295,
    54296, 54298, 54298, 54298, 54298, 54298, 54298, 54298, 54298, 54306, 54308, 54309, 54310,
    54310, 54311, 54313, 54314, 54316, 54317, 54317, 54318, 54320, 54329, 54331, 54331, 54332,
    54338, 54340, 54340, 54340, 54341, 54343, 54344, 54346, 54347, 54349, 54411, 54411, 54411,
    54411, 54413, 54413, 54413, 54421, 54422, 54422, 54424, 54426, 54426, 54426, 54426, 54426,
    54426, 54427, 54429, 54439, 54441, 54441, 54441, 54450, 54451, 54453, 54455, 54456, 54456,
    54457, 54459, 54470, 54472, 54472, 54483, 54484, 54486, 54487, 54492, 54497, 54498, 54516,
    54518, 54523, 54524, 54526, 54528, 54529, 54531, 54533, 54533, 54533, 54533, 54534, 54536,
    54538, 54539, 54550, 54552, 54552, 54558, 54568, 54570, 54570, 54574, 54576, 54578, 54578,
    54578, 54579, 54584, 54585, 54586, 54587, 54589, 54595, 54597, 54597, 54597, 54597, 54597,
    54597, 54608, 54610, 54611, 54612, 54614, 54616, 54617, 54619, 54634, 54636, 54646, 54647,
    54649, 54655, 54657, 54657, 54662, 54664, 54666, 54668, 54669, 54673, 54675, 54687, 54689,
    55116, 55118, 55120, 55122, 55124, 55126, 55127, 55128, 55129, 55130, 55131, 55218, 55232,
    55232, 55234, 55234, 55237, 55239, 55257, 55262, 55263, 55268, 55270, 55270, 55271, 55276,
    55278, 55278, 55283, 55286, 55288, 55288, 55288, 55291, 55294, 55296, 55299, 55411, 55411,
    55413, 55422, 55424, 55425, 55430, 55432, 55435, 55437, 55437, 55442, 55444, 55450, 55452,
    55452, 55457, 55459, 55459, 55469, 55471, 55471, 55481, 55483, 55483, 55483, 55483, 55483,
    55483, 55483, 55483, 55483, 55483, 55483, 55483, 55487, 55490, 55491, 55494, 55496, 55497,
    55499, 55543, 55545, 55546, 55559, 55566, 55566, 55568, 55569, 55571, 55576, 55576, 55578,
    55578, 55583, 55585, 55585, 55590, 55592, 55593, 55595, 55595, 55596, 55597, 55599, 55599,
    55606, 55606, 55606, 55608, 55608, 55608, 55608, 55608, 55608, 55608, 55608, 55618, 55619,
    55621, 55624, 55626, 55627, 55629, 55743, 55756, 55758, 55758, 55758, 55758, 55765, 55765,
    55767, 55767, 55768, 55774, 55776, 55777, 55779, 56068, 56070, 56070, 56072, 56073, 56075,
    56076, 56077, 56112, 56130, 56132, 56132, 56132, 56133, 56154, 56170, 56179, 56182, 56191,
    56203, 56204, 56206, 56218, 56220, 56220, 56235, 56237, 56242, 56242, 56244, 56244, 56244,
    56249, 56253, 56254, 56269, 56269, 56271, 56271, 56271, 56271, 56276, 56281, 56283, 56283,
    56283, 56283, 56288, 56288, 56288, 56290, 56290, 56290, 56291, 56294, 56295, 56299, 56305,
    56307, 56307, 56316, 56317, 56321, 56322, 56323, 56329, 56330, 56332, 56332, 56333, 56335,
    56337, 56337, 56338, 56338, 56340, 56341, 56346, 56346, 56346, 56346, 56348, 56348, 56348,
    56349, 56355, 56355, 56355, 56355, 56355, 56355, 56357, 56368, 56370, 56370, 56370, 56377,
    56379, 56379, 56379, 56379, 56379, 56410, 56412, 56412, 56414, 56422, 56424, 56424, 56424,
    56427, 56428, 56457, 56459, 56462, 56470, 56472, 56472, 56472, 56472, 56477, 56479, 56564,
    56566, 56567, 56575, 56579, 56581, 56584, 56587, 56588, 56589, 56593, 56594, 56598, 56599,
    56626, 56630, 56637, 56642, 56645, 56648, 56651, 56653, 56656, 56659, 56727, 56729, 56729,
    56736, 56743, 56745, 56745, 56746, 56751, 56753, 56753, 56754, 56759, 56761, 56761, 56766,
    56766, 56767, 56769, 56812, 56814, 56814, 56814, 56814, 56814, 56818, 56820, 56821, 56823,
    56825, 56826, 56828, 56829, 56841, 56843, 56843, 56843, 56850, 56850, 56850, 56856, 56858,
    56858, 56858, 56858, 56858, 56858, 56859, 56859, 56861, 56862, 56864, 56865, 56867, 56867,
    56869, 57518, 57520, 57520, 57520, 57520, 57520, 57520, 57520, 57520, 57537, 57539, 57539,
    57548, 57555, 57562, 57567, 57572, 57577, 57578, 57580, 57581, 57583, 57583, 57584, 57586,
    57587, 57589, 57610, 57612, 57612, 57614, 57614, 57614, 57627, 57627, 57629, 57629, 57629,
    57629, 57629, 57629, 57629, 57629, 57632, 57635, 57636, 57638, 57639, 57641, 57642, 57642,
    57644, 57645, 57647, 57648, 65391, 65558, 65558, 65558, 65558, 65558, 65582, 65623, 65624,
    65626, 65629, 66482, 66484, 66484, 66497, 66500, 66501, 66503, 66504, 66506, 66507, 66509,
    66849, 66851, 66851, 66851, 66851, 66862, 66869, 66871, 66871, 66877, 66879, 66879, 66882,
    66885, 66887, 66887, 66892, 66894, 66894, 66901, 66903, 66903, 66904, 66907, 66909, 66909,
    66909, 66914, 66916, 66917, 66919, 66953, 66954, 66955, 66955, 66957, 66957, 66957, 66969,
    66976, 66978, 66981, 66987, 66987, 66989, 66989, 66994, 66996, 66996, 66999, 66999, 67059,
    67061, 67063, 67065, 67067, 67067, 67069, 67071, 67098, 67105, 67112, 67117, 67122, 67125,
    67126, 67127, 67133, 67134, 67136, 67141, 67146, 67147, 67149, 67150, 67152, 67157, 67158,
    67159, 67161, 67165, 67166, 67167, 67169, 67227, 67229, 67240, 67245, 67246, 67251, 67256,
    67256, 67256, 67258, 67259, 67269, 67271, 67271, 67271, 67271, 67273, 67273, 67278, 67280,
    67281, 67283, 67292, 67294, 67294, 67295, 67297, 67304, 67305, 67307, 67308, 67310, 67311,
    67316, 67317, 67319, 67346, 67354, 67360, 67361, 67363, 67365, 67366, 67368, 67373, 67374,
    67376, 67377, 67377, 67377, 67378, 67433, 67434, 67435, 67435, 67454, 67459, 67466, 67468,
    67468, 67468, 67468, 67471, 67472, 67473, 67475, 67480, 67480, 67482, 67482, 67482, 67483,
    67483, 67487, 67489, 67489, 67547, 67549, 67550, 67551, 67574, 67575, 67577, 67578, 67580,
    67582, 67583, 67585, 67586, 67587, 67590, 67591, 67591, 67591, 67592, 67593, 67595, 67596,
    67598, 67599, 67655, 67657, 67659, 67661, 67661, 67663, 67677, 67678, 67680, 67681, 67681,
    67685, 67686, 67688, 67691, 67693, 67693, 67697, 67699, 67700, 67701, 67705, 67706, 67707,
    67714, 67715, 67716, 67718, 67722, 67724, 67724, 67725, 67727, 67728, 67729, 67731, 67732,
    67734, 67735, 67737, 67742, 67742, 67742, 67744, 67744, 67744, 67744, 67744, 67744, 67744,
    67744, 67744, 67745, 67745, 67746, 67748, 67749, 67752, 67753, 67753, 67753, 67754, 67756,
    67757, 67759, 67759, 67759, 67759, 67806, 67808, 67808, 67808, 67808, 67808, 67811, 67813,
    67814, 67816, 67817, 67819, 67821, 67822, 67822, 67822, 67823, 67823, 67824, 67826, 67827,
    67829, 76726, 76744, 76751, 76756, 76761, 76764, 76767, 76768, 76770, 76771, 76773, 76774,
    76776, 76777, 76779, 76829, 76829, 76829, 76829, 76831, 76831, 76833, 76835, 76835, 76846,
    76848, 76848, 76855, 76855, 76857, 76863, 76865, 76870, 76872, 76872, 76877, 76879, 76879,
    76887, 76887, 76887, 76887, 76889, 76891,
  ],
  Saarland: [
    66111, 66113, 66115, 66117, 66119, 66121, 66123, 66125, 66126, 66127, 66128, 66129, 66130,
    66131, 66132, 66133, 66265, 66271, 66280, 66287, 66292, 66299, 66333, 66346, 66352, 66359,
    66386, 66399, 66424, 66440, 66450, 66453, 66459, 66538, 66539, 66540, 66557, 66564, 66571,
    66578, 66583, 66589, 66606, 66620, 66625, 66629, 66636, 66640, 66646, 66649, 66663, 66679,
    66687, 66693, 66701, 66706, 66709, 66740, 66763, 66773, 66780, 66787, 66793, 66798, 66802,
    66806, 66809, 66822, 66839,
  ],
  Niedersachsen: [
    19273, 21217, 21218, 21220, 21224, 21227, 21228, 21244, 21244, 21255, 21256, 21258, 21259,
    21261, 21266, 21271, 21272, 21274, 21279, 21335, 21337, 21339, 21354, 21357, 21358, 21360,
    21365, 21368, 21369, 21371, 21376, 21379, 21380, 21382, 21385, 21386, 21388, 21391, 21391,
    21394, 21395, 21397, 21398, 21400, 21401, 21403, 21406, 21407, 21409, 21423, 21423, 21423,
    21435, 21436, 21438, 21439, 21441, 21442, 21444, 21444, 21445, 21447, 21449, 21522, 21614,
    21629, 21635, 21640, 21641, 21643, 21644, 21646, 21647, 21649, 21680, 21682, 21683, 21684,
    21698, 21702, 21706, 21709, 21710, 21712, 21714, 21717, 21720, 21723, 21723, 21726, 21727,
    21729, 21730, 21732, 21734, 21737, 21739, 21745, 21755, 21756, 21762, 21763, 21765, 21769,
    21770, 21772, 21775, 21776, 21781, 21782, 21785, 21787, 21789, 26121, 26122, 26123, 26125,
    26127, 26129, 26131, 26133, 26135, 26160, 26169, 26180, 26188, 26197, 26203, 26209, 26215,
    26219, 26316, 26340, 26345, 26349, 26382, 26384, 26386, 26388, 26389, 26409, 26419, 26427,
    26434, 26441, 26446, 26452, 26465, 26474, 26486, 26487, 26489, 26506, 26524, 26529, 26532,
    26548, 26553, 26556, 26571, 26571, 26579, 26603, 26605, 26607, 26624, 26629, 26632, 26639,
    26655, 26670, 26676, 26683, 26689, 26721, 26723, 26725, 26736, 26757, 26759, 26789, 26802,
    26810, 26817, 26826, 26831, 26835, 26842, 26844, 26845, 26847, 26849, 26871, 26892, 26897,
    26899, 26901, 26903, 26904, 26906, 26907, 26909, 26909, 26919, 26931, 26935, 26936, 26937,
    26939, 26954, 26954, 26954, 26969, 27211, 27232, 27239, 27243, 27245, 27246, 27248, 27249,
    27251, 27252, 27254, 27257, 27259, 27283, 27299, 27305, 27308, 27313, 27318, 27321, 27324,
    27327, 27330, 27330, 27333, 27336, 27337, 27339, 27356, 27356, 27367, 27374, 27383, 27386,
    27389, 27404, 27412, 27419, 27432, 27442, 27446, 27449, 27472, 27474, 27476, 27478, 27607,
    27612, 27616, 27619, 27624, 27628, 27639, 27711, 27721, 27726, 27726, 27729, 27749, 27751,
    27753, 27755, 27755, 27755, 27777, 27793, 27798, 27801, 27804, 27809, 28790, 28816, 28832,
    28844, 28857, 28865, 28870, 28876, 28879, 29221, 29223, 29225, 29227, 29229, 29303, 29308,
    29313, 29320, 29323, 29328, 29331, 29336, 29339, 29342, 29345, 29348, 29351, 29352, 29353,
    29355, 29356, 29358, 29359, 29361, 29362, 29364, 29364, 29364, 29365, 29367, 29369, 29378,
    29379, 29386, 29389, 29392, 29393, 29394, 29396, 29396, 29399, 29439, 29451, 29456, 29459,
    29462, 29465, 29468, 29471, 29472, 29473, 29475, 29476, 29478, 29479, 29481, 29482, 29484,
    29485, 29487, 29488, 29490, 29491, 29493, 29494, 29496, 29497, 29499, 29525, 29549, 29553,
    29556, 29559, 29562, 29565, 29571, 29574, 29575, 29576, 29578, 29579, 29581, 29582, 29584,
    29585, 29587, 29588, 29590, 29591, 29593, 29594, 29597, 29599, 29614, 29633, 29640, 29640,
    29643, 29646, 29649, 29664, 29683, 29690, 29693, 29699, 30159, 30159, 30161, 30161, 30163,
    30165, 30167, 30169, 30171, 30173, 30175, 30177, 30179, 30419, 30449, 30451, 30453, 30455,
    30457, 30459, 30519, 30521, 30539, 30559, 30625, 30627, 30629, 30655, 30657, 30659, 30669,
    30823, 30826, 30827, 30851, 30853, 30855, 30880, 30890, 30900, 30916, 30926, 30938, 30952,
    30966, 30974, 30982, 30989, 31008, 31020, 31028, 31036, 31061, 31073, 31079, 31079, 31084,
    31084, 31089, 31134, 31135, 31137, 31139, 31141, 31157, 31162, 31167, 31171, 31174, 31177,
    31180, 31185, 31188, 31191, 31195, 31199, 31224, 31226, 31228, 31234, 31241, 31246, 31249,
    31275, 31303, 31311, 31319, 31515, 31535, 31542, 31547, 31552, 31553, 31555, 31556, 31558,
    31559, 31582, 31592, 31595, 31600, 31603, 31604, 31606, 31608, 31609, 31613, 31618, 31619,
    31621, 31622, 31623, 31626, 31627, 31628, 31629, 31632, 31633, 31634, 31636, 31637, 31638,
    31655, 31675, 31683, 31688, 31691, 31693, 31698, 31699, 31700, 31702, 31707, 31708, 31710,
    31711, 31712, 31714, 31715, 31717, 31718, 31719, 31737, 31749, 31785, 31787, 31789, 31789,
    31789, 31789, 31812, 31832, 31840, 31848, 31855, 31860, 31863, 31867, 31868, 34346, 34355,
    37073, 37075, 37077, 37079, 37081, 37083, 37085, 37085, 37115, 37120, 37124, 37127, 37130,
    37133, 37136, 37139, 37154, 37170, 37170, 37176, 37181, 37186, 37191, 37194, 37197, 37197,
    37199, 37412, 37412, 37431, 37434, 37441, 37444, 37445, 37520, 37539, 37574, 37581, 37586,
    37589, 37603, 37619, 37620, 37627, 37632, 37633, 37635, 37639, 37640, 37642, 37643, 37647,
    37649, 37691, 37691, 37697, 37699, 38100, 38102, 38104, 38106, 38108, 38110, 38112, 38114,
    38116, 38118, 38120, 38122, 38124, 38126, 38154, 38154, 38154, 38159, 38162, 38165, 38170,
    38173, 38176, 38179, 38226, 38228, 38229, 38239, 38259, 38268, 38271, 38272, 38274, 38275,
    38277, 38279, 38300, 38302, 38304, 38312, 38315, 38319, 38321, 38322, 38324, 38325, 38327,
    38329, 38350, 38364, 38368, 38372, 38373, 38375, 38376, 38378, 38379, 38381, 38382, 38384,
    38387, 38440, 38442, 38444, 38446, 38448, 38458, 38459, 38461, 38462, 38464, 38465, 38467,
    38468, 38470, 38471, 38473, 38474, 38476, 38477, 38479, 38518, 38524, 38527, 38528, 38530,
    38531, 38533, 38536, 38539, 38542, 38543, 38547, 38550, 38551, 38553, 38554, 38556, 38557,
    38559, 38640, 38642, 38642, 38642, 38644, 38644, 38644, 38644, 38667, 38678, 38685, 38690,
    38700, 38704, 38707, 38709, 38723, 38729, 48455, 48465, 48480, 48488, 48499, 48527, 48529,
    48531, 49074, 49076, 49078, 49080, 49082, 49084, 49086, 49088, 49090, 49124, 49134, 49143,
    49152, 49163, 49170, 49176, 49179, 49186, 49191, 49196, 49201, 49205, 49214, 49219, 49324,
    49326, 49328, 49356, 49377, 49393, 49401, 49406, 49413, 49419, 49424, 49429, 49434, 49439,
    49448, 49451, 49453, 49456, 49457, 49459, 49459, 49565, 49577, 49584, 49586, 49593, 49594,
    49596, 49597, 49599, 49610, 49624, 49626, 49632, 49635, 49637, 49638, 49661, 49681, 49685,
    49688, 49692, 49696, 49699, 49716, 49733, 49740, 49744, 49751, 49757, 49762, 49767, 49770,
    49774, 49777, 49779, 49808, 49809, 49811, 49824, 49828, 49832, 49835, 49838, 49843, 49844,
    49846, 49847, 49849,
  ],
  'Baden-Württemberg': [
    63928, 64754, 68159, 68161, 68163, 68165, 68167, 68169, 68199, 68219, 68229, 68239, 68259,
    68305, 68307, 68309, 68526, 68535, 68542, 68549, 68723, 68753, 68766, 68775, 68782, 68789,
    68794, 68799, 68804, 68809, 69115, 69117, 69118, 69120, 69121, 69123, 69124, 69126, 69151,
    69168, 69181, 69181, 69190, 69198, 69207, 69214, 69221, 69226, 69231, 69234, 69242, 69245,
    69250, 69251, 69253, 69254, 69256, 69257, 69259, 69412, 69427, 69429, 69429, 69434, 69436,
    69437, 69439, 69469, 69493, 69502, 69514, 70173, 70174, 70176, 70178, 70180, 70182, 70184,
    70186, 70188, 70190, 70191, 70192, 70193, 70195, 70197, 70199, 70327, 70329, 70372, 70374,
    70376, 70378, 70435, 70437, 70439, 70439, 70469, 70499, 70563, 70565, 70567, 70569, 70597,
    70599, 70619, 70629, 70734, 70736, 70771, 70794, 70806, 70825, 70839, 71032, 71034, 71063,
    71063, 71065, 71067, 71069, 71069, 71083, 71088, 71093, 71101, 71106, 71111, 71111, 71116,
    71120, 71126, 71131, 71134, 71139, 71144, 71149, 71154, 71155, 71157, 71159, 71229, 71254,
    71263, 71272, 71277, 71282, 71287, 71292, 71296, 71297, 71299, 71332, 71334, 71336, 71364,
    71384, 71394, 71397, 71397, 71404, 71409, 71522, 71540, 71543, 71546, 71549, 71554, 71560,
    71563, 71566, 71570, 71573, 71576, 71577, 71579, 71634, 71636, 71638, 71640, 71642, 71665,
    71672, 71672, 71672, 71679, 71686, 71691, 71696, 71701, 71706, 71711, 71717, 71720, 71723,
    71726, 71729, 71732, 71735, 71737, 71739, 72070, 72072, 72074, 72076, 72108, 72108, 72108,
    72116, 72119, 72124, 72127, 72131, 72135, 72138, 72141, 72141, 72144, 72145, 72145, 72147,
    72149, 72160, 72172, 72175, 72178, 72181, 72184, 72186, 72189, 72202, 72213, 72218, 72221,
    72224, 72226, 72227, 72229, 72250, 72250, 72250, 72250, 72270, 72275, 72280, 72285, 72290,
    72293, 72294, 72296, 72296, 72297, 72299, 72336, 72348, 72351, 72355, 72356, 72358, 72359,
    72361, 72362, 72364, 72365, 72367, 72369, 72379, 72393, 72401, 72406, 72411, 72414, 72415,
    72417, 72419, 72458, 72459, 72461, 72469, 72474, 72475, 72477, 72479, 72488, 72501, 72505,
    72510, 72511, 72513, 72514, 72516, 72517, 72519, 72525, 72531, 72532, 72534, 72535, 72537,
    72539, 72555, 72574, 72581, 72582, 72584, 72585, 72587, 72589, 72622, 72631, 72636, 72639,
    72644, 72649, 72654, 72655, 72657, 72657, 72658, 72660, 72661, 72663, 72663, 72664, 72666,
    72667, 72669, 72760, 72762, 72762, 72764, 72764, 72766, 72766, 72768, 72770, 72793, 72800,
    72805, 72810, 72813, 72818, 72820, 72827, 72829, 73033, 73035, 73037, 73054, 73061, 73066,
    73072, 73072, 73079, 73084, 73087, 73092, 73095, 73098, 73099, 73101, 73102, 73104, 73105,
    73107, 73107, 73108, 73110, 73111, 73113, 73114, 73116, 73117, 73119, 73207, 73230, 73235,
    73240, 73249, 73252, 73257, 73257, 73262, 73265, 73266, 73268, 73269, 73271, 73272, 73274,
    73275, 73277, 73278, 73312, 73326, 73329, 73333, 73337, 73340, 73342, 73344, 73345, 73347,
    73349, 73430, 73431, 73432, 73433, 73434, 73441, 73447, 73450, 73453, 73457, 73460, 73463,
    73466, 73467, 73469, 73469, 73479, 73485, 73486, 73488, 73489, 73491, 73492, 73494, 73495,
    73497, 73497, 73499, 73525, 73527, 73529, 73529, 73540, 73547, 73547, 73550, 73553, 73553,
    73557, 73560, 73563, 73565, 73566, 73566, 73568, 73569, 73571, 73572, 73574, 73575, 73577,
    73579, 73614, 73630, 73635, 73642, 73650, 73655, 73660, 73663, 73666, 73667, 73669, 73728,
    73730, 73732, 73733, 73734, 73760, 73765, 73770, 73773, 73776, 73779, 74072, 74074, 74076,
    74078, 74080, 74081, 74172, 74177, 74182, 74189, 74193, 74196, 74199, 74206, 74211, 74214,
    74219, 74223, 74226, 74229, 74232, 74235, 74238, 74239, 74243, 74245, 74246, 74248, 74249,
    74251, 74252, 74254, 74255, 74257, 74259, 74321, 74321, 74336, 74343, 74348, 74354, 74354,
    74357, 74360, 74363, 74366, 74369, 74372, 74374, 74376, 74379, 74382, 74385, 74388, 74389,
    74391, 74392, 74394, 74395, 74397, 74399, 74405, 74417, 74420, 74423, 74424, 74426, 74426,
    74427, 74429, 74523, 74532, 74532, 74535, 74538, 74541, 74542, 74544, 74545, 74547, 74549,
    74564, 74572, 74575, 74579, 74582, 74585, 74586, 74589, 74592, 74594, 74595, 74597, 74597,
    74599, 74613, 74626, 74629, 74632, 74635, 74638, 74639, 74653, 74670, 74670, 74673, 74676,
    74677, 74679, 74706, 74722, 74731, 74736, 74740, 74743, 74744, 74746, 74747, 74749, 74821,
    74831, 74834, 74838, 74842, 74847, 74850, 74855, 74858, 74861, 74862, 74864, 74865, 74867,
    74869, 74889, 74906, 74909, 74912, 74915, 74918, 74921, 74924, 74925, 74927, 74928, 74930,
    74931, 74933, 74934, 74936, 74937, 74939, 75015, 75031, 75038, 75045, 75050, 75053, 75056,
    75057, 75059, 75172, 75173, 75175, 75177, 75179, 75180, 75181, 75196, 75203, 75210, 75217,
    75223, 75228, 75233, 75236, 75239, 75242, 75245, 75248, 75249, 75305, 75323, 75328, 75331,
    75334, 75335, 75337, 75339, 75365, 75378, 75382, 75385, 75387, 75389, 75391, 75392, 75394,
    75395, 75397, 75397, 75399, 75417, 75428, 75433, 75438, 75443, 75446, 75447, 75449, 76131,
    76133, 76135, 76137, 76139, 76149, 76185, 76187, 76189, 76199, 76227, 76228, 76229, 76275,
    76287, 76297, 76307, 76316, 76327, 76332, 76337, 76344, 76351, 76356, 76359, 76437, 76448,
    76456, 76461, 76467, 76470, 76473, 76474, 76476, 76477, 76479, 76530, 76532, 76532, 76534,
    76547, 76549, 76571, 76593, 76596, 76597, 76599, 76646, 76646, 76661, 76669, 76676, 76684,
    76689, 76694, 76698, 76703, 76706, 76707, 76709, 77652, 77654, 77656, 77694, 77704, 77709,
    77716, 77723, 77728, 77731, 77736, 77740, 77743, 77746, 77749, 77756, 77761, 77767, 77770,
    77773, 77776, 77781, 77784, 77787, 77790, 77791, 77793, 77794, 77796, 77797, 77799, 77815,
    77830, 77833, 77836, 77839, 77855, 77866, 77871, 77876, 77880, 77880, 77883, 77886, 77887,
    77889, 77933, 77948, 77955, 77960, 77963, 77966, 77971, 77972, 77974, 77975, 77977, 77978,
    78048, 78050, 78052, 78052, 78052, 78054, 78056, 78073, 78078, 78083, 78086, 78087, 78089,
    78089, 78089, 78089, 78089, 78098, 78112, 78120, 78126, 78132, 78136, 78141, 78144, 78147,
    78148, 78166, 78166, 78176, 78183, 78187, 78194, 78199, 78224, 78234, 78239, 78244, 78247,
    78250, 78253, 78256, 78259, 78262, 78266, 78267, 78269, 78315, 78333, 78333, 78337, 78343,
    78345, 78351, 78354, 78355, 78357, 78359, 78462, 78464, 78465, 78465, 78467, 78476, 78479,
    78479, 78479, 78479, 78479, 78479, 78532, 78549, 78554, 78559, 78564, 78567, 78570, 78573,
    78576, 78579, 78580, 78582, 78583, 78585, 78586, 78588, 78589, 78591, 78592, 78594, 78595,
    78597, 78598, 78600, 78601, 78603, 78604, 78606, 78607, 78609, 78628, 78628, 78647, 78652,
    78655, 78658, 78661, 78662, 78664, 78665, 78667, 78669, 78713, 78727, 78730, 78733, 78736,
    78737, 78739, 79098, 79100, 79102, 79104, 79106, 79106, 79106, 79106, 79106, 79108, 79108,
    79110, 79110, 79111, 79112, 79114, 79115, 79115, 79117, 79117, 79183, 79189, 79194, 79199,
    79206, 79211, 79215, 79219, 79224, 79227, 79232, 79235, 79238, 79241, 79244, 79249, 79252,
    79254, 79256, 79258, 79261, 79263, 79268, 79271, 79271, 79274, 79276, 79279, 79280, 79282,
    79283, 79285, 79286, 79288, 79289, 79291, 79292, 79294, 79295, 79297, 79299, 79312, 79331,
    79336, 79341, 79346, 79348, 79350, 79353, 79356, 79359, 79361, 79362, 79364, 79364, 79365,
    79367, 79369, 79379, 79395, 79395, 79400, 79410, 79415, 79418, 79423, 79423, 79424, 79426,
    79427, 79429, 79539, 79540, 79541, 79576, 79585, 79588, 79589, 79591, 79592, 79594, 79595,
    79595, 79597, 79599, 79618, 79639, 79650, 79664, 79669, 79674, 79677, 79682, 79685, 79686,
    79688, 79689, 79692, 79694, 79695, 79713, 79725, 79730, 79733, 79736, 79737, 79739, 79761,
    79771, 79774, 79777, 79780, 79787, 79790, 79793, 79798, 79801, 79802, 79804, 79805, 79807,
    79809, 79822, 79837, 79843, 79848, 79853, 79856, 79859, 79862, 79865, 79868, 79871, 79872,
    79874, 79875, 79877, 79879, 88045, 88046, 88048, 88069, 88074, 88079, 88085, 88090, 88094,
    88097, 88099, 88147, 88212, 88213, 88213, 88214, 88239, 88250, 88255, 88260, 88263, 88267,
    88271, 88273, 88276, 88279, 88281, 88284, 88285, 88285, 88287, 88289, 88299, 88316, 88317,
    88319, 88326, 88339, 88348, 88348, 88353, 88356, 88361, 88364, 88367, 88368, 88370, 88371,
    88373, 88374, 88376, 88377, 88379, 88379, 88400, 88410, 88416, 88422, 88427, 88430, 88433,
    88436, 88437, 88441, 88444, 88447, 88448, 88450, 88451, 88453, 88454, 88456, 88457, 88457,
    88459, 88471, 88471, 88477, 88480, 88481, 88483, 88484, 88486, 88487, 88489, 88499, 88499,
    88512, 88512, 88515, 88518, 88521, 88524, 88525, 88527, 88529, 88605, 88630, 88631, 88633,
    88634, 88636, 88637, 88637, 88639, 88662, 88662, 88677, 88682, 88690, 88693, 88696, 88697,
    88699, 88709, 88709, 88718, 88719, 89073, 89075, 89077, 89079, 89081, 89081, 89129, 89134,
    89143, 89150, 89155, 89160, 89160, 89165, 89165, 89168, 89168, 89171, 89173, 89174, 89176,
    89177, 89179, 89180, 89182, 89183, 89185, 89186, 89188, 89189, 89191, 89192, 89194, 89195,
    89197, 89198, 89518, 89520, 89522, 89537, 89542, 89542, 89547, 89551, 89555, 89558, 89561,
    89564, 89567, 89568, 89584, 89584, 89597, 89601, 89604, 89604, 89605, 89605, 89607, 89608,
    89610, 89611, 89613, 89614, 89616, 89617, 89619, 97877, 97896, 97900, 97922, 97941, 97944,
    97947, 97950, 97953, 97956, 97957, 97959, 97980, 97990, 97993, 97996, 97999,
  ],
  'Schleswig-Holstein': [
    21039, 21465, 21465, 21481, 21483, 21493, 21502, 21502, 21509, 21514, 21516, 21516, 21521,
    21521, 21524, 21526, 21527, 21529, 22113, 22113, 22145, 22844, 22844, 22846, 22848, 22850,
    22851, 22869, 22880, 22885, 22889, 22889, 22889, 22926, 22927, 22929, 22929, 22929, 22929,
    22929, 22929, 22929, 22929, 22941, 22946, 22946, 22946, 22946, 22949, 22952, 22955, 22956,
    22958, 22959, 22961, 22962, 22964, 22965, 22967, 22969, 23552, 23554, 23556, 23558, 23560,
    23562, 23564, 23566, 23568, 23569, 23570, 23611, 23617, 23619, 23623, 23626, 23627, 23628,
    23629, 23669, 23683, 23684, 23689, 23701, 23714, 23714, 23715, 23717, 23719, 23730, 23738,
    23743, 23744, 23746, 23747, 23749, 23758, 23769, 23769, 23774, 23775, 23777, 23779, 23795,
    23812, 23813, 23815, 23816, 23818, 23820, 23821, 23823, 23824, 23826, 23827, 23829, 23843,
    23845, 23845, 23845, 23845, 23845, 23845, 23847, 23847, 23858, 23860, 23860, 23863, 23863,
    23866, 23867, 23869, 23879, 23881, 23881, 23883, 23896, 23898, 23899, 23909, 23911, 23911,
    23919, 24103, 24105, 24106, 24107, 24107, 24109, 24109, 24111, 24113, 24113, 24114, 24116,
    24118, 24119, 24143, 24145, 24146, 24147, 24148, 24149, 24159, 24161, 24211, 24214, 24217,
    24220, 24220, 24222, 24223, 24226, 24229, 24232, 24235, 24238, 24239, 24241, 24242, 24244,
    24245, 24247, 24248, 24250, 24251, 24253, 24254, 24256, 24257, 24259, 24306, 24321, 24326,
    24326, 24327, 24329, 24340, 24351, 24354, 24357, 24358, 24360, 24361, 24363, 24364, 24366,
    24367, 24369, 24376, 24392, 24395, 24398, 24399, 24401, 24402, 24404, 24405, 24407, 24407,
    24409, 24534, 24536, 24536, 24537, 24539, 24558, 24568, 24576, 24582, 24589, 24594, 24598,
    24601, 24610, 24613, 24613, 24616, 24616, 24619, 24619, 24620, 24622, 24623, 24625, 24625,
    24626, 24628, 24629, 24631, 24632, 24634, 24635, 24637, 24638, 24640, 24641, 24643, 24644,
    24646, 24647, 24649, 24768, 24782, 24783, 24784, 24787, 24790, 24791, 24793, 24794, 24796,
    24797, 24799, 24799, 24799, 24799, 24800, 24802, 24803, 24805, 24806, 24808, 24809, 24811,
    24813, 24814, 24816, 24817, 24819, 24837, 24848, 24848, 24850, 24852, 24855, 24857, 24860,
    24861, 24863, 24864, 24866, 24867, 24869, 24870, 24872, 24873, 24876, 24878, 24879, 24881,
    24882, 24884, 24885, 24887, 24888, 24890, 24891, 24893, 24894, 24896, 24896, 24897, 24899,
    24937, 24939, 24941, 24943, 24943, 24944, 24955, 24960, 24963, 24966, 24969, 24972, 24975,
    24976, 24977, 24980, 24983, 24986, 24988, 24989, 24991, 24992, 24994, 24996, 24997, 24999,
    25335, 25335, 25336, 25337, 25348, 25355, 25358, 25361, 25364, 25365, 25368, 25370, 25371,
    25373, 25376, 25377, 25377, 25379, 25421, 25436, 25451, 25462, 25469, 25474, 25479, 25482,
    25485, 25486, 25488, 25489, 25491, 25491, 25492, 25494, 25495, 25497, 25499, 25524, 25541,
    25548, 25551, 25554, 25557, 25560, 25563, 25563, 25566, 25569, 25572, 25573, 25575, 25576,
    25578, 25579, 25581, 25582, 25584, 25585, 25587, 25588, 25590, 25591, 25593, 25594, 25596,
    25597, 25599, 25693, 25704, 25709, 25712, 25715, 25718, 25718, 25719, 25721, 25724, 25725,
    25725, 25727, 25729, 25746, 25761, 25764, 25767, 25767, 25770, 25774, 25776, 25776, 25779,
    25782, 25785, 25786, 25788, 25791, 25792, 25794, 25795, 25797, 25799, 25813, 25821, 25821,
    25826, 25832, 25836, 25840, 25842, 25845, 25845, 25845, 25849, 25849, 25850, 25852, 25853,
    25855, 25856, 25858, 25859, 25859, 25860, 25862, 25863, 25864, 25866, 25867, 25869, 25869,
    25870, 25872, 25873, 25876, 25878, 25879, 25881, 25882, 25884, 25885, 25887, 25889, 25899,
    25917, 25920, 25923, 25924, 25926, 25927, 25938, 25946, 25980, 25992, 25996, 25997, 25999,
    27498, 27498,
  ],
  Hessen: [
    34117, 34119, 34121, 34123, 34125, 34127, 34127, 34128, 34130, 34131, 34132, 34134, 34212,
    34225, 34233, 34233, 34246, 34253, 34260, 34266, 34270, 34277, 34281, 34286, 34289, 34289,
    34292, 34295, 34298, 34302, 34305, 34308, 34311, 34314, 34317, 34320, 34323, 34326, 34327,
    34329, 34355, 34359, 34369, 34376, 34379, 34385, 34388, 34393, 34396, 34399, 34399, 34399,
    34454, 34466, 34471, 34474, 34477, 34479, 34497, 34508, 34513, 34516, 34519, 34537, 34549,
    34560, 34576, 34582, 34587, 34590, 34593, 34596, 34599, 34613, 34621, 34626, 34628, 34630,
    34632, 34633, 34637, 34639, 35037, 35039, 35041, 35043, 35066, 35075, 35080, 35083, 35085,
    35088, 35091, 35094, 35096, 35099, 35102, 35104, 35108, 35110, 35112, 35114, 35116, 35117,
    35119, 35216, 35232, 35236, 35239, 35260, 35274, 35279, 35282, 35285, 35287, 35288, 35305,
    35315, 35321, 35325, 35327, 35329, 35390, 35392, 35394, 35396, 35398, 35410, 35415, 35418,
    35423, 35428, 35435, 35440, 35444, 35447, 35452, 35457, 35460, 35463, 35466, 35469, 35510,
    35516, 35519, 35576, 35578, 35579, 35580, 35581, 35582, 35583, 35584, 35585, 35586, 35606,
    35614, 35619, 35625, 35630, 35633, 35638, 35641, 35644, 35647, 35649, 35683, 35684, 35685,
    35686, 35687, 35688, 35689, 35690, 35708, 35713, 35716, 35719, 35745, 35753, 35756, 35759,
    35764, 35767, 35768, 35781, 35789, 35792, 35794, 35796, 35799, 36037, 36039, 36041, 36043,
    36088, 36093, 36100, 36103, 36110, 36115, 36119, 36124, 36129, 36132, 36137, 36142, 36145,
    36148, 36151, 36154, 36157, 36160, 36163, 36166, 36167, 36169, 36179, 36199, 36205, 36208,
    36211, 36214, 36217, 36219, 36251, 36266, 36269, 36272, 36275, 36277, 36280, 36282, 36284,
    36286, 36287, 36289, 36304, 36318, 36320, 36323, 36325, 36326, 36329, 36341, 36355, 36358,
    36364, 36367, 36369, 36381, 36391, 36396, 36399, 37194, 37213, 37214, 37215, 37216, 37217,
    37218, 37235, 37242, 37247, 37247, 37249, 37269, 37276, 37281, 37284, 37287, 37290, 37293,
    37296, 37297, 37299, 55246, 55252, 59969, 60306, 60308, 60310, 60311, 60313, 60314, 60316,
    60318, 60320, 60322, 60323, 60325, 60326, 60327, 60329, 60385, 60386, 60388, 60389, 60431,
    60433, 60435, 60437, 60438, 60439, 60486, 60487, 60488, 60489, 60528, 60529, 60549, 60594,
    60596, 60598, 60599, 61118, 61130, 61137, 61138, 61169, 61184, 61191, 61194, 61197, 61200,
    61203, 61206, 61209, 61231, 61239, 61250, 61267, 61273, 61276, 61279, 61348, 61350, 61352,
    61381, 61389, 61440, 61449, 61462, 61476, 61479, 63065, 63067, 63069, 63071, 63071, 63071,
    63073, 63075, 63110, 63128, 63150, 63165, 63179, 63225, 63263, 63303, 63322, 63329, 63450,
    63452, 63454, 63456, 63457, 63477, 63486, 63500, 63505, 63512, 63517, 63526, 63533, 63538,
    63543, 63546, 63549, 63571, 63579, 63584, 63589, 63594, 63599, 63607, 63619, 63628, 63633,
    63636, 63637, 63639, 63654, 63667, 63674, 63679, 63683, 63688, 63691, 63694, 63695, 63697,
    63699, 64283, 64285, 64287, 64289, 64291, 64293, 64295, 64297, 64319, 64331, 64342, 64347,
    64354, 64367, 64372, 64380, 64385, 64390, 64395, 64395, 64397, 64401, 64404, 64405, 64407,
    64409, 64521, 64546, 64560, 64569, 64572, 64579, 64584, 64589, 64625, 64646, 64646, 64653,
    64658, 64665, 64668, 64673, 64678, 64683, 64686, 64689, 64711, 64720, 64732, 64739, 64747,
    64750, 64753, 64756, 64757, 64760, 64807, 64823, 64832, 64839, 64846, 64850, 64853, 64859,
    65183, 65185, 65187, 65189, 65191, 65193, 65195, 65197, 65199, 65201, 65203, 65205, 65207,
    65232, 65239, 65307, 65321, 65326, 65329, 65343, 65344, 65345, 65346, 65347, 65366, 65375,
    65385, 65388, 65391, 65396, 65399, 65428, 65439, 65451, 65462, 65468, 65474, 65479, 65510,
    65520, 65527, 65529, 65549, 65550, 65551, 65552, 65553, 65554, 65555, 65556, 65589, 65594,
    65597, 65599, 65604, 65606, 65611, 65614, 65618, 65620, 65627, 65719, 65760, 65779, 65795,
    65812, 65817, 65824, 65830, 65835, 65843, 65929, 65931, 65933, 65934, 65936, 68519, 68623,
    68642, 68647, 68649, 69239, 69412, 69434, 69483, 69488, 69509, 69517, 69518,
  ],
  Thüringen: [
    4600, 4603, 4603, 4610, 4613, 4617, 4617, 4618, 4618, 4626, 4639, 6556, 6567, 6571, 6577, 6578,
    6578, 7318, 7330, 7333, 7338, 7343, 7349, 7356, 7366, 7368, 7381, 7387, 7389, 7389, 7407, 7422,
    7426, 7427, 7429, 7545, 7546, 7548, 7549, 7551, 7551, 7552, 7554, 7554, 7557, 7557, 7570, 7580,
    7586, 7589, 7607, 7613, 7616, 7619, 7629, 7639, 7646, 7743, 7745, 7747, 7749, 7751, 7751, 7751,
    7751, 7751, 7751, 7751, 7751, 7768, 7774, 7778, 7778, 7806, 7819, 7907, 7919, 7919, 7922, 7924,
    7926, 7927, 7929, 7937, 7950, 7955, 7957, 7958, 7973, 7980, 7980, 7980, 7980, 7987, 36404,
    36414, 36419, 36433, 36448, 36452, 36452, 36456, 36457, 36460, 36466, 36469, 37308, 37318,
    37327, 37339, 37345, 37351, 37355, 37359, 96515, 96523, 96524, 96528, 98527, 98528, 98529,
    98530, 98530, 98530, 98544, 98547, 98553, 98554, 98559, 98559, 98559, 98559, 98574, 98587,
    98590, 98593, 98596, 98597, 98617, 98630, 98631, 98634, 98639, 98646, 98660, 98663, 98666,
    98667, 98669, 98673, 98693, 98694, 98694, 98694, 98701, 98711, 98716, 98724, 98743, 98744,
    98746, 98746, 99084, 99085, 99086, 99087, 99089, 99090, 99091, 99092, 99094, 99095, 99096,
    99097, 99098, 99099, 99100, 99102, 99102, 99102, 99102, 99189, 99192, 99195, 99198, 99198,
    99198, 99198, 99310, 99326, 99330, 99334, 99338, 99423, 99425, 99427, 99428, 99428, 99438,
    99439, 99441, 99444, 99448, 99510, 99518, 99610, 99625, 99628, 99631, 99634, 99636, 99638,
    99706, 99707, 99713, 99718, 99734, 99735, 99752, 99755, 99759, 99765, 99768, 99817, 99819,
    99819, 99820, 99826, 99830, 99831, 99834, 99837, 99842, 99846, 99848, 99867, 99869, 99880,
    99885, 99887, 99891, 99894, 99897, 99897, 99947, 99955, 99958, 99958, 99974, 99976, 99986,
    99988, 99991, 99994, 99996, 99998,
  ],
  'Mecklenburg-Vorpommern': [
    17033, 17034, 17036, 17039, 17039, 17087, 17089, 17091, 17094, 17098, 17099, 17099, 17109,
    17111, 17121, 17126, 17129, 17139, 17153, 17154, 17159, 17166, 17168, 17179, 17192, 17194,
    17207, 17209, 17213, 17214, 17217, 17219, 17235, 17237, 17237, 17248, 17252, 17255, 17258,
    17309, 17321, 17322, 17322, 17322, 17328, 17329, 17335, 17337, 17337, 17337, 17337, 17348,
    17349, 17358, 17367, 17373, 17375, 17375, 17375, 17375, 17379, 17389, 17390, 17391, 17392,
    17398, 17406, 17419, 17424, 17429, 17438, 17438, 17438, 17440, 17440, 17440, 17440, 17440,
    17440, 17440, 17440, 17440, 17449, 17449, 17449, 17449, 17449, 17449, 17449, 17454, 17459,
    17489, 17489, 17491, 17493, 17493, 17493, 17493, 17493, 17495, 17498, 17506, 17509, 18055,
    18057, 18059, 18059, 18069, 18069, 18106, 18107, 18107, 18109, 18119, 18146, 18147, 18181,
    18181, 18182, 18182, 18184, 18190, 18195, 18196, 18198, 18209, 18211, 18211, 18225, 18230,
    18233, 18236, 18239, 18246, 18249, 18258, 18273, 18276, 18279, 18292, 18299, 18311, 18314,
    18317, 18317, 18320, 18334, 18337, 18347, 18356, 18356, 18374, 18374, 18375, 18435, 18437,
    18439, 18439, 18439, 18442, 18445, 18445, 18461, 18465, 18469, 18507, 18510, 18513, 18516,
    18519, 18528, 18546, 18551, 18556, 18565, 18565, 18569, 18569, 18569, 18569, 18569, 18573,
    18573, 18574, 18581, 18581, 18586, 18609, 19053, 19055, 19055, 19057, 19059, 19061, 19063,
    19065, 19067, 19069, 19071, 19073, 19073, 19075, 19077, 19079, 19086, 19089, 19205, 19209,
    19217, 19230, 19243, 19246, 19249, 19258, 19260, 19273, 19288, 19294, 19300, 19303, 19306,
    19357, 19370, 19372, 19372, 19374, 19376, 19386, 19395, 19399, 19406, 19412, 19417, 23923,
    23936, 23942, 23946, 23946, 23948, 23966, 23966, 23966, 23968, 23970, 23972, 23974, 23992,
    23996, 23999,
  ],
  Bayern: [
    63739, 63741, 63743, 63755, 63762, 63768, 63773, 63776, 63785, 63791, 63796, 63801, 63801,
    63808, 63811, 63814, 63820, 63825, 63826, 63828, 63829, 63831, 63834, 63839, 63840, 63843,
    63846, 63849, 63853, 63856, 63857, 63860, 63863, 63864, 63867, 63868, 63869, 63871, 63872,
    63874, 63875, 63877, 63879, 63897, 63906, 63911, 63916, 63916, 63920, 63924, 63925, 63927,
    63928, 63930, 63931, 63933, 63934, 63936, 63936, 63937, 63939, 74731, 80331, 80333, 80335,
    80336, 80337, 80339, 80469, 80538, 80539, 80634, 80636, 80637, 80638, 80639, 80686, 80687,
    80689, 80796, 80797, 80798, 80799, 80801, 80802, 80803, 80804, 80805, 80807, 80809, 80933,
    80935, 80937, 80939, 80992, 80993, 80995, 80997, 80999, 81241, 81243, 81245, 81247, 81249,
    81369, 81371, 81373, 81375, 81377, 81379, 81475, 81476, 81477, 81479, 81539, 81541, 81543,
    81545, 81545, 81547, 81549, 81667, 81669, 81671, 81673, 81675, 81677, 81679, 81735, 81737,
    81739, 81825, 81827, 81829, 81925, 81927, 81929, 82008, 82024, 82031, 82041, 82049, 82054,
    82057, 82061, 82064, 82065, 82067, 82069, 82110, 82131, 82131, 82140, 82152, 82152, 82166,
    82178, 82194, 82205, 82211, 82216, 82223, 82229, 82234, 82237, 82239, 82256, 82266, 82269,
    82272, 82275, 82276, 82278, 82279, 82281, 82284, 82285, 82287, 82288, 82290, 82291, 82293,
    82294, 82296, 82297, 82299, 82319, 82327, 82335, 82340, 82343, 82346, 82347, 82349, 82362,
    82377, 82380, 82383, 82386, 82387, 82389, 82390, 82392, 82393, 82395, 82396, 82398, 82399,
    82401, 82401, 82402, 82404, 82405, 82407, 82409, 82418, 82431, 82432, 82433, 82435, 82436,
    82438, 82439, 82439, 82441, 82442, 82444, 82445, 82447, 82449, 82467, 82475, 82481, 82487,
    82488, 82490, 82491, 82493, 82494, 82496, 82497, 82499, 82515, 82538, 82541, 82544, 82547,
    82549, 83022, 83024, 83026, 83043, 83052, 83059, 83064, 83071, 83075, 83080, 83083, 83088,
    83093, 83098, 83101, 83104, 83109, 83112, 83115, 83119, 83122, 83123, 83125, 83126, 83128,
    83129, 83131, 83132, 83134, 83135, 83137, 83139, 83209, 83224, 83229, 83233, 83236, 83242,
    83246, 83250, 83253, 83254, 83256, 83257, 83259, 83278, 83301, 83308, 83313, 83317, 83324,
    83329, 83334, 83339, 83342, 83346, 83349, 83352, 83355, 83358, 83361, 83362, 83364, 83365,
    83367, 83368, 83370, 83371, 83373, 83374, 83376, 83377, 83379, 83395, 83404, 83410, 83413,
    83416, 83417, 83435, 83451, 83454, 83457, 83458, 83471, 83483, 83486, 83487, 83487, 83512,
    83527, 83530, 83533, 83536, 83539, 83543, 83544, 83546, 83547, 83549, 83550, 83553, 83555,
    83556, 83558, 83559, 83561, 83562, 83564, 83567, 83569, 83607, 83620, 83623, 83624, 83626,
    83627, 83629, 83646, 83661, 83666, 83670, 83671, 83673, 83674, 83676, 83677, 83679, 83684,
    83700, 83703, 83707, 83708, 83714, 83727, 83730, 83734, 83735, 83737, 84028, 84030, 84030,
    84032, 84032, 84034, 84036, 84036, 84048, 84051, 84056, 84061, 84066, 84069, 84072, 84076,
    84079, 84082, 84085, 84088, 84089, 84091, 84092, 84094, 84095, 84097, 84098, 84100, 84101,
    84103, 84104, 84106, 84107, 84109, 84130, 84137, 84140, 84144, 84149, 84152, 84155, 84160,
    84163, 84164, 84166, 84168, 84169, 84171, 84172, 84174, 84175, 84177, 84178, 84180, 84181,
    84183, 84184, 84186, 84187, 84189, 84307, 84323, 84326, 84329, 84332, 84333, 84335, 84337,
    84339, 84347, 84359, 84364, 84367, 84371, 84375, 84378, 84381, 84384, 84385, 84387, 84389,
    84405, 84416, 84419, 84424, 84427, 84428, 84431, 84432, 84434, 84435, 84437, 84439, 84453,
    84478, 84489, 84494, 84503, 84508, 84513, 84513, 84518, 84524, 84529, 84533, 84539, 84543,
    84544, 84546, 84547, 84549, 84550, 84552, 84553, 84555, 84556, 84558, 84559, 84561, 84562,
    84564, 84565, 84567, 84568, 84570, 84571, 84573, 84574, 84576, 84577, 84579, 85049, 85051,
    85053, 85055, 85057, 85072, 85077, 85080, 85084, 85088, 85092, 85095, 85098, 85101, 85104,
    85107, 85110, 85111, 85113, 85114, 85116, 85117, 85119, 85120, 85122, 85123, 85125, 85126,
    85128, 85129, 85131, 85132, 85134, 85135, 85137, 85139, 85221, 85229, 85232, 85235, 85238,
    85241, 85244, 85247, 85250, 85253, 85254, 85256, 85258, 85259, 85276, 85283, 85290, 85293,
    85296, 85298, 85301, 85302, 85304, 85305, 85307, 85307, 85309, 85354, 85356, 85368, 85375,
    85376, 85386, 85391, 85395, 85399, 85402, 85405, 85406, 85408, 85410, 85411, 85413, 85414,
    85416, 85417, 85419, 85435, 85445, 85447, 85452, 85456, 85457, 85459, 85461, 85462, 85464,
    85465, 85467, 85469, 85521, 85540, 85540, 85551, 85560, 85567, 85570, 85570, 85579, 85586,
    85591, 85598, 85599, 85604, 85609, 85614, 85617, 85622, 85622, 85625, 85630, 85635, 85640,
    85643, 85646, 85649, 85652, 85653, 85656, 85658, 85659, 85661, 85662, 85664, 85665, 85667,
    85669, 85716, 85737, 85748, 85757, 85764, 85764, 85774, 85777, 85778, 86150, 86152, 86153,
    86154, 86156, 86157, 86159, 86161, 86163, 86165, 86167, 86169, 86179, 86199, 86316, 86343,
    86356, 86368, 86381, 86391, 86399, 86405, 86415, 86420, 86424, 86438, 86441, 86444, 86447,
    86450, 86453, 86456, 86459, 86462, 86465, 86465, 86470, 86473, 86476, 86477, 86479, 86480,
    86482, 86483, 86485, 86486, 86488, 86489, 86491, 86492, 86494, 86495, 86497, 86498, 86500,
    86502, 86504, 86505, 86507, 86508, 86510, 86511, 86513, 86514, 86517, 86519, 86529, 86551,
    86554, 86556, 86558, 86559, 86561, 86562, 86564, 86565, 86567, 86568, 86570, 86571, 86573,
    86574, 86576, 86577, 86579, 86609, 86633, 86637, 86641, 86643, 86647, 86650, 86653, 86655,
    86657, 86660, 86663, 86666, 86668, 86669, 86672, 86673, 86674, 86675, 86676, 86678, 86679,
    86681, 86682, 86684, 86685, 86687, 86688, 86690, 86692, 86694, 86695, 86697, 86698, 86700,
    86701, 86703, 86704, 86706, 86707, 86709, 86720, 86732, 86733, 86735, 86736, 86738, 86739,
    86741, 86742, 86744, 86745, 86747, 86748, 86750, 86751, 86753, 86754, 86756, 86757, 86759,
    86807, 86825, 86830, 86833, 86836, 86836, 86842, 86845, 86850, 86853, 86854, 86856, 86857,
    86859, 86860, 86862, 86863, 86865, 86866, 86868, 86869, 86871, 86872, 86874, 86875, 86877,
    86879, 86899, 86911, 86916, 86919, 86920, 86922, 86923, 86925, 86926, 86928, 86929, 86931,
    86932, 86934, 86935, 86937, 86938, 86940, 86941, 86943, 86944, 86946, 86947, 86949, 86956,
    86971, 86972, 86974, 86975, 86977, 86978, 86980, 86981, 86983, 86984, 86986, 86987, 86989,
    87435, 87437, 87439, 87448, 87452, 87459, 87463, 87466, 87471, 87474, 87477, 87480, 87484,
    87487, 87488, 87490, 87493, 87494, 87496, 87497, 87499, 87509, 87527, 87534, 87538, 87541,
    87544, 87545, 87547, 87549, 87561, 87600, 87616, 87629, 87634, 87637, 87640, 87642, 87645,
    87647, 87648, 87648, 87650, 87651, 87653, 87654, 87656, 87657, 87659, 87660, 87662, 87663,
    87665, 87666, 87668, 87669, 87671, 87672, 87674, 87675, 87677, 87679, 87700, 87719, 87724,
    87727, 87730, 87733, 87734, 87736, 87737, 87739, 87740, 87742, 87743, 87745, 87746, 87748,
    87749, 87751, 87752, 87754, 87755, 87757, 87758, 87760, 87761, 87763, 87764, 87766, 87767,
    87769, 87770, 87772, 87773, 87775, 87776, 87778, 87779, 87781, 87782, 87784, 87785, 87787,
    87789, 88131, 88138, 88142, 88145, 88149, 88161, 88167, 88171, 88175, 88178, 88179, 89081,
    89081, 89231, 89233, 89250, 89257, 89264, 89269, 89275, 89278, 89281, 89284, 89287, 89290,
    89291, 89293, 89294, 89296, 89297, 89299, 89312, 89331, 89335, 89340, 89343, 89344, 89346,
    89347, 89349, 89350, 89352, 89353, 89355, 89356, 89358, 89359, 89361, 89362, 89364, 89365,
    89367, 89368, 89407, 89415, 89420, 89423, 89426, 89428, 89429, 89431, 89434, 89435, 89437,
    89438, 89440, 89441, 89443, 89446, 89447, 90402, 90403, 90408, 90409, 90411, 90411, 90419,
    90425, 90427, 90429, 90431, 90439, 90441, 90443, 90449, 90451, 90453, 90455, 90455, 90459,
    90461, 90469, 90471, 90471, 90473, 90475, 90478, 90480, 90482, 90489, 90491, 90513, 90518,
    90522, 90530, 90537, 90542, 90547, 90552, 90556, 90559, 90562, 90571, 90574, 90579, 90584,
    90587, 90592, 90596, 90599, 90602, 90607, 90610, 90613, 90614, 90616, 90617, 90619, 90762,
    90763, 90765, 90766, 90768, 91052, 91054, 91054, 91056, 91058, 91074, 91077, 91080, 91083,
    91085, 91086, 91088, 91090, 91091, 91093, 91093, 91094, 91096, 91097, 91099, 91126, 91126,
    91154, 91161, 91166, 91171, 91174, 91177, 91180, 91183, 91186, 91187, 91189, 91207, 91217,
    91220, 91224, 91227, 91230, 91233, 91235, 91236, 91238, 91239, 91241, 91242, 91244, 91245,
    91247, 91249, 91257, 91275, 91278, 91281, 91282, 91284, 91286, 91287, 91289, 91301, 91315,
    91320, 91322, 91325, 91327, 91330, 91332, 91334, 91336, 91338, 91338, 91341, 91341, 91344,
    91346, 91347, 91349, 91350, 91352, 91353, 91355, 91356, 91358, 91359, 91361, 91362, 91364,
    91365, 91367, 91369, 91413, 91438, 91443, 91448, 91452, 91456, 91459, 91460, 91462, 91463,
    91465, 91466, 91468, 91469, 91471, 91472, 91474, 91475, 91477, 91477, 91478, 91480, 91481,
    91483, 91484, 91486, 91487, 91489, 91522, 91541, 91550, 91555, 91560, 91564, 91567, 91572,
    91575, 91578, 91580, 91583, 91586, 91587, 91589, 91590, 91592, 91593, 91595, 91596, 91598,
    91599, 91601, 91602, 91604, 91605, 91607, 91608, 91610, 91611, 91613, 91614, 91616, 91617,
    91619, 91620, 91622, 91623, 91625, 91626, 91628, 91629, 91631, 91632, 91634, 91635, 91637,
    91639, 91710, 91717, 91719, 91720, 91722, 91723, 91725, 91726, 91728, 91729, 91731, 91732,
    91734, 91735, 91737, 91738, 91740, 91741, 91743, 91744, 91746, 91747, 91749, 91757, 91781,
    91785, 91788, 91790, 91792, 91793, 91795, 91796, 91798, 91799, 91801, 91802, 91804, 91805,
    91807, 91809, 92224, 92237, 92242, 92245, 92249, 92253, 92256, 92259, 92260, 92262, 92263,
    92265, 92265, 92266, 92266, 92268, 92269, 92271, 92272, 92274, 92275, 92277, 92278, 92280,
    92281, 92283, 92284, 92286, 92287, 92289, 92318, 92331, 92334, 92339, 92342, 92345, 92348,
    92353, 92355, 92358, 92360, 92361, 92363, 92364, 92366, 92367, 92369, 92421, 92431, 92436,
    92439, 92442, 92444, 92445, 92447, 92449, 92507, 92521, 92526, 92533, 92536, 92539, 92540,
    92542, 92543, 92545, 92546, 92548, 92549, 92551, 92552, 92554, 92555, 92557, 92559, 92637,
    92637, 92648, 92655, 92660, 92665, 92670, 92676, 92681, 92685, 92690, 92693, 92694, 92696,
    92697, 92699, 92700, 92702, 92703, 92705, 92706, 92708, 92709, 92711, 92712, 92714, 92715,
    92717, 92718, 92720, 92721, 92723, 92723, 92724, 92726, 92727, 92729, 93047, 93049, 93051,
    93053, 93055, 93057, 93059, 93073, 93077, 93080, 93083, 93086, 93087, 93089, 93090, 93092,
    93093, 93095, 93096, 93098, 93099, 93101, 93102, 93104, 93105, 93107, 93109, 93109, 93128,
    93133, 93138, 93142, 93149, 93152, 93155, 93158, 93161, 93164, 93167, 93170, 93173, 93176,
    93177, 93179, 93180, 93182, 93183, 93185, 93186, 93188, 93189, 93191, 93192, 93194, 93195,
    93197, 93197, 93199, 93309, 93326, 93333, 93336, 93339, 93342, 93343, 93345, 93346, 93348,
    93349, 93351, 93352, 93354, 93356, 93358, 93359, 93413, 93426, 93426, 93437, 93444, 93449,
    93453, 93455, 93458, 93462, 93464, 93466, 93468, 93470, 93471, 93473, 93474, 93476, 93477,
    93479, 93480, 93482, 93483, 93485, 93486, 93488, 93489, 93491, 93492, 93494, 93495, 93497,
    93499, 94032, 94034, 94036, 94036, 94051, 94060, 94065, 94072, 94078, 94081, 94086, 94089,
    94094, 94099, 94104, 94107, 94110, 94113, 94116, 94118, 94121, 94124, 94127, 94130, 94133,
    94136, 94137, 94139, 94140, 94142, 94143, 94145, 94146, 94148, 94149, 94151, 94152, 94154,
    94157, 94158, 94160, 94161, 94163, 94164, 94166, 94167, 94169, 94209, 94227, 94234, 94239,
    94244, 94249, 94250, 94252, 94253, 94255, 94256, 94258, 94259, 94261, 94262, 94264, 94265,
    94267, 94269, 94315, 94327, 94330, 94333, 94336, 94339, 94342, 94344, 94345, 94347, 94348,
    94350, 94351, 94353, 94354, 94356, 94357, 94359, 94360, 94362, 94363, 94365, 94366, 94368,
    94369, 94371, 94372, 94374, 94375, 94377, 94379, 94405, 94419, 94424, 94428, 94431, 94436,
    94437, 94439, 94447, 94469, 94474, 94481, 94486, 94491, 94496, 94501, 94505, 94508, 94513,
    94518, 94522, 94526, 94527, 94529, 94530, 94532, 94533, 94535, 94536, 94538, 94539, 94541,
    94542, 94544, 94545, 94547, 94548, 94550, 94551, 94553, 94554, 94556, 94557, 94559, 94560,
    94562, 94563, 94566, 94568, 94569, 94571, 94572, 94574, 94575, 94577, 94579, 95028, 95030,
    95032, 95100, 95111, 95119, 95126, 95131, 95138, 95145, 95152, 95158, 95163, 95163, 95168,
    95173, 95176, 95179, 95180, 95182, 95183, 95185, 95186, 95188, 95189, 95191, 95192, 95194,
    95195, 95197, 95199, 95213, 95233, 95234, 95236, 95237, 95239, 95326, 95336, 95339, 95346,
    95349, 95352, 95355, 95356, 95358, 95359, 95361, 95362, 95364, 95365, 95367, 95369, 95444,
    95445, 95447, 95448, 95460, 95460, 95463, 95466, 95469, 95473, 95478, 95482, 95485, 95485,
    95488, 95490, 95491, 95493, 95494, 95496, 95496, 95497, 95499, 95500, 95502, 95503, 95503,
    95505, 95505, 95506, 95508, 95509, 95511, 95512, 95514, 95515, 95517, 95519, 95615, 95632,
    95643, 95652, 95659, 95666, 95671, 95676, 95679, 95680, 95682, 95683, 95685, 95686, 95688,
    95689, 95691, 95692, 95694, 95694, 95695, 95697, 95698, 95700, 95701, 95703, 95704, 95706,
    95707, 95709, 96047, 96049, 96050, 96052, 96103, 96106, 96110, 96114, 96117, 96120, 96123,
    96126, 96129, 96132, 96135, 96138, 96142, 96145, 96146, 96148, 96149, 96151, 96152, 96154,
    96155, 96157, 96158, 96160, 96161, 96163, 96164, 96166, 96167, 96169, 96170, 96172, 96173,
    96175, 96176, 96178, 96179, 96181, 96182, 96184, 96185, 96187, 96188, 96190, 96191, 96193,
    96194, 96196, 96197, 96199, 96215, 96224, 96231, 96237, 96242, 96247, 96250, 96253, 96257,
    96260, 96264, 96268, 96269, 96271, 96272, 96274, 96275, 96277, 96279, 96317, 96328, 96332,
    96337, 96342, 96346, 96349, 96352, 96355, 96358, 96361, 96364, 96365, 96367, 96369, 96369,
    96450, 96465, 96472, 96476, 96479, 96482, 96484, 96486, 96487, 96489, 97070, 97072, 97074,
    97076, 97078, 97080, 97082, 97084, 97199, 97204, 97209, 97215, 97218, 97222, 97225, 97228,
    97230, 97232, 97234, 97236, 97237, 97239, 97241, 97243, 97244, 97246, 97247, 97249, 97250,
    97252, 97253, 97255, 97256, 97258, 97259, 97261, 97262, 97264, 97265, 97267, 97268, 97270,
    97271, 97273, 97274, 97276, 97277, 97279, 97280, 97282, 97283, 97285, 97286, 97288, 97289,
    97291, 97292, 97294, 97295, 97297, 97299, 97318, 97320, 97320, 97320, 97332, 97334, 97337,
    97340, 97342, 97346, 97348, 97348, 97348, 97350, 97353, 97355, 97357, 97359, 97421, 97422,
    97424, 97437, 97440, 97447, 97450, 97453, 97456, 97461, 97464, 97469, 97475, 97478, 97483,
    97486, 97488, 97490, 97491, 97493, 97494, 97496, 97497, 97499, 97500, 97502, 97503, 97505,
    97506, 97508, 97509, 97511, 97513, 97514, 97516, 97517, 97519, 97520, 97522, 97523, 97525,
    97526, 97528, 97529, 97531, 97532, 97534, 97535, 97537, 97539, 97616, 97618, 97618, 97631,
    97633, 97638, 97638, 97640, 97640, 97645, 97647, 97650, 97653, 97654, 97656, 97657, 97659,
    97688, 97702, 97705, 97708, 97711, 97714, 97717, 97720, 97723, 97724, 97725, 97727, 97729,
    97737, 97753, 97762, 97769, 97772, 97773, 97775, 97776, 97778, 97779, 97780, 97782, 97783,
    97785, 97786, 97788, 97789, 97791, 97792, 97794, 97795, 97797, 97799, 97816, 97828, 97833,
    97834, 97836, 97837, 97839, 97839, 97839, 97839, 97839, 97840, 97840, 97842, 97843, 97845,
    97846, 97848, 97849, 97851, 97852, 97854, 97855, 97857, 97859, 97892, 97896, 97901, 97903,
    97904, 97906, 97907, 97909,
  ],
  'Sachsen-Anhalt': [
    6108, 6110, 6112, 6114, 6116, 6118, 6120, 6122, 6124, 6126, 6128, 6130, 6132, 6179, 6184, 6188,
    6193, 6198, 6217, 6231, 6237, 6242, 6246, 6249, 6255, 6258, 6259, 6268, 6268, 6268, 6279, 6295,
    6308, 6311, 6313, 6317, 6333, 6333, 6333, 6333, 6343, 6347, 6366, 6369, 6369, 6385, 6386, 6388,
    6406, 6408, 6420, 6425, 6429, 6429, 6449, 6456, 6458, 6463, 6464, 6466, 6467, 6469, 6484, 6485,
    6493, 6493, 6493, 6493, 6502, 6526, 6528, 6528, 6536, 6536, 6536, 6537, 6542, 6542, 6543, 6618,
    6628, 6632, 6632, 6636, 6638, 6642, 6647, 6647, 6648, 6667, 6667, 6679, 6682, 6686, 6688, 6711,
    6712, 6721, 6722, 6729, 6729, 6749, 6766, 6772, 6772, 6773, 6774, 6779, 6780, 6785, 6792, 6794,
    6796, 6800, 6803, 6808, 6809, 6842, 6842, 6844, 6846, 6847, 6849, 6861, 6862, 6862, 6868, 6869,
    6886, 6888, 6888, 6889, 6895, 6901, 6905, 6917, 6925, 14715, 29410, 29413, 29416, 38486, 38489,
    38820, 38822, 38828, 38829, 38835, 38836, 38838, 38855, 38871, 38875, 38877, 38879, 38889,
    38895, 38899, 39104, 39106, 39108, 39110, 39112, 39114, 39114, 39114, 39114, 39114, 39114,
    39116, 39118, 39120, 39122, 39124, 39126, 39128, 39130, 39164, 39167, 39171, 39175, 39175,
    39179, 39217, 39217, 39218, 39221, 39240, 39245, 39249, 39261, 39264, 39264, 39279, 39288,
    39291, 39307, 39317, 39319, 39326, 39340, 39343, 39343, 39345, 39356, 39359, 39365, 39387,
    39393, 39397, 39397, 39418, 39435, 39439, 39443, 39444, 39446, 39448, 39448, 39448, 39517,
    39517, 39524, 39539, 39576, 39579, 39590, 39596, 39606, 39615, 39619, 39624, 39628, 39629,
    39638, 39638, 39646, 39646, 39649,
  ],
  Brandenburg: [
    1945, 1968, 1979, 1983, 1987, 1990, 1993, 1994, 1996, 1998, 3042, 3044, 3046, 3048, 3050, 3051,
    3052, 3053, 3054, 3055, 3058, 3096, 3099, 3103, 3116, 3119, 3130, 3149, 3159, 3172, 3185, 3197,
    3205, 3222, 3226, 3229, 3238, 3246, 3249, 3253, 4895, 4910, 4916, 4924, 4928, 4931, 4932, 4934,
    4936, 4938, 12529, 14467, 14469, 14471, 14473, 14476, 14476, 14478, 14480, 14482, 14513, 14532,
    14542, 14547, 14548, 14550, 14552, 14554, 14558, 14558, 14612, 14621, 14624, 14641, 14656,
    14662, 14669, 14712, 14715, 14727, 14728, 14770, 14772, 14774, 14776, 14778, 14778, 14789,
    14793, 14797, 14798, 14806, 14822, 14823, 14827, 14828, 14913, 14929, 14943, 14947, 14959,
    14974, 14979, 15230, 15232, 15234, 15236, 15236, 15236, 15236, 15236, 15236, 15295, 15299,
    15306, 15320, 15324, 15326, 15328, 15344, 15345, 15345, 15366, 15370, 15374, 15377, 15378,
    15517, 15518, 15518, 15518, 15526, 15528, 15537, 15562, 15566, 15569, 15711, 15712, 15713,
    15732, 15738, 15741, 15745, 15746, 15748, 15749, 15754, 15755, 15757, 15806, 15827, 15831,
    15831, 15834, 15837, 15838, 15848, 15859, 15864, 15868, 15890, 15898, 15907, 15910, 15913,
    15926, 15936, 15938, 16225, 16227, 16230, 16230, 16244, 16247, 16248, 16259, 16269, 16278,
    16303, 16306, 16306, 16307, 16321, 16341, 16348, 16356, 16359, 16515, 16540, 16547, 16548,
    16552, 16556, 16559, 16562, 16567, 16727, 16761, 16766, 16767, 16775, 16792, 16798, 16816,
    16818, 16818, 16818, 16827, 16831, 16833, 16835, 16837, 16845, 16845, 16845, 16845, 16866,
    16866, 16868, 16909, 16928, 16945, 16949, 17268, 17279, 17291, 17326, 17337, 17337, 19309,
    19322, 19322, 19336, 19339, 19348, 19348, 19357,
  ],
  Sachsen: [
    1067, 1069, 1069, 1097, 1099, 1108, 1109, 1127, 1127, 1129, 1139, 1156, 1157, 1159, 1169, 1187,
    1187, 1189, 1217, 1219, 1237, 1239, 1257, 1257, 1259, 1277, 1279, 1307, 1309, 1324, 1326, 1328,
    1445, 1454, 1458, 1465, 1465, 1468, 1471, 1477, 1558, 1561, 1587, 1589, 1591, 1594, 1609, 1612,
    1616, 1619, 1623, 1640, 1662, 1665, 1683, 1689, 1705, 1723, 1728, 1731, 1734, 1737, 1738, 1738,
    1744, 1762, 1768, 1773, 1774, 1776, 1778, 1796, 1809, 1814, 1816, 1819, 1824, 1825, 1829, 1833,
    1844, 1847, 1848, 1855, 1855, 1877, 1896, 1900, 1904, 1906, 1909, 1917, 1920, 1936, 2625, 2627,
    2627, 2633, 2681, 2689, 2692, 2694, 2699, 2708, 2727, 2730, 2733, 2736, 2739, 2742, 2747, 2748,
    2763, 2779, 2782, 2785, 2788, 2791, 2794, 2796, 2797, 2799, 2826, 2827, 2828, 2829, 2894, 2899,
    2906, 2923, 2929, 2943, 2943, 2953, 2956, 2957, 2957, 2957, 2959, 2977, 2979, 2991, 2994, 2997,
    2999, 4103, 4105, 4107, 4109, 4129, 4155, 4157, 4158, 4159, 4177, 4178, 4179, 4205, 4207, 4209,
    4229, 4249, 4275, 4277, 4279, 4288, 4289, 4299, 4315, 4316, 4317, 4318, 4319, 4328, 4329, 4347,
    4349, 4356, 4357, 4416, 4420, 4425, 4435, 4442, 4451, 4463, 4509, 4519, 4523, 4539, 4552, 4564,
    4565, 4567, 4571, 4575, 4643, 4651, 4654, 4668, 4680, 4683, 4687, 4703, 4720, 4736, 4741, 4746,
    4749, 4758, 4769, 4774, 4779, 4808, 4821, 4824, 4827, 4828, 4838, 4849, 4860, 4861, 4862, 4874,
    4880, 4886, 4889, 7919, 7919, 7952, 7985, 8056, 8058, 8060, 8062, 8064, 8066, 8107, 8112, 8115,
    8118, 8132, 8134, 8141, 8144, 8147, 8209, 8223, 8228, 8233, 8236, 8237, 8239, 8248, 8258, 8261,
    8262, 8267, 8280, 8289, 8294, 8297, 8301, 8304, 8309, 8315, 8321, 8324, 8328, 8340, 8344, 8349,
    8352, 8359, 8371, 8373, 8393, 8396, 8412, 8427, 8428, 8451, 8459, 8468, 8485, 8491, 8496, 8499,
    8523, 8523, 8523, 8525, 8527, 8529, 8538, 8539, 8541, 8543, 8547, 8548, 8606, 8626, 8645, 8648,
    9111, 9112, 9113, 9114, 9116, 9117, 9117, 9119, 9120, 9122, 9123, 9125, 9126, 9127, 9128, 9130,
    9131, 9212, 9217, 9221, 9224, 9228, 9232, 9235, 9236, 9241, 9243, 9244, 9247, 9249, 9306, 9322,
    9326, 9328, 9337, 9350, 9353, 9355, 9356, 9366, 9376, 9380, 9385, 9387, 9390, 9392, 9394, 9399,
    9405, 9419, 9423, 9427, 9429, 9430, 9432, 9434, 9437, 9437, 9439, 9456, 9465, 9468, 9471, 9474,
    9477, 9481, 9484, 9487, 9488, 9496, 9509, 9514, 9518, 9526, 9544, 9548, 9557, 9569, 9573, 9573,
    9575, 9577, 9579, 9599, 9600, 9600, 9603, 9618, 9619, 9623, 9627, 9629, 9633, 9634, 9638, 9648,
    9661, 9669,
  ],
  Berlin: [
    10115, 10117, 10119, 10178, 10179, 10243, 10245, 10247, 10249, 10315, 10317, 10318, 10319,
    10365, 10367, 10369, 10405, 10407, 10409, 10435, 10437, 10439, 10551, 10553, 10555, 10557,
    10559, 10585, 10587, 10589, 10623, 10623, 10625, 10627, 10629, 10707, 10709, 10711, 10713,
    10715, 10717, 10719, 10777, 10779, 10781, 10783, 10785, 10787, 10789, 10823, 10825, 10827,
    10829, 10961, 10963, 10965, 10967, 10969, 10997, 10999, 12043, 12045, 12047, 12049, 12051,
    12053, 12055, 12057, 12059, 12059, 12099, 12101, 12103, 12105, 12107, 12107, 12109, 12157,
    12159, 12161, 12163, 12165, 12167, 12169, 12203, 12205, 12207, 12209, 12247, 12249, 12277,
    12279, 12305, 12305, 12307, 12309, 12347, 12349, 12351, 12353, 12355, 12357, 12359, 12435,
    12437, 12439, 12459, 12487, 12489, 12524, 12526, 12527, 12555, 12557, 12559, 12559, 12587,
    12589, 12619, 12621, 12623, 12623, 12627, 12629, 12679, 12679, 12681, 12683, 12685, 12685,
    12687, 12689, 13051, 13053, 13055, 13057, 13059, 13086, 13088, 13089, 13125, 13127, 13129,
    13156, 13158, 13159, 13187, 13189, 13347, 13349, 13351, 13353, 13355, 13357, 13359, 13403,
    13405, 13407, 13409, 13435, 13437, 13439, 13465, 13467, 13469, 13503, 13505, 13507, 13509,
    13581, 13583, 13585, 13587, 13589, 13591, 13593, 13595, 13597, 13599, 13627, 13629, 14050,
    14052, 14053, 14055, 14057, 14059, 14089, 14109, 14129, 14163, 14165, 14167, 14169, 14193,
    14195, 14197, 14199,
  ],
  Bremen: [
    27568, 27568, 27570, 27572, 27574, 27576, 27578, 27578, 27580, 28195, 28197, 28199, 28201,
    28203, 28205, 28207, 28209, 28211, 28213, 28215, 28217, 28219, 28237, 28239, 28259, 28277,
    28279, 28307, 28309, 28325, 28327, 28329, 28329, 28355, 28357, 28359, 28717, 28719, 28755,
    28755, 28757, 28759, 28777, 28779,
  ],
  Hamburg: [
    20095, 20097, 20099, 20144, 20146, 20148, 20149, 20249, 20251, 20253, 20255, 20257, 20259,
    20354, 20355, 20357, 20359, 20457, 20459, 20535, 20537, 20539, 21029, 21031, 21033, 21035,
    21037, 21039, 21073, 21075, 21077, 21079, 21107, 21109, 21129, 21147, 21149, 22041, 22043,
    22045, 22047, 22049, 22081, 22083, 22085, 22087, 22089, 22111, 22113, 22113, 22115, 22117,
    22119, 22143, 22145, 22147, 22149, 22159, 22175, 22177, 22179, 22297, 22299, 22301, 22303,
    22305, 22307, 22309, 22335, 22337, 22339, 22359, 22391, 22393, 22395, 22397, 22399, 22415,
    22415, 22417, 22419, 22453, 22453, 22453, 22455, 22457, 22457, 22459, 22523, 22525, 22527,
    22529, 22547, 22549, 22559, 22587, 22589, 22605, 22607, 22609, 22609, 22761, 22763, 22765,
    22767, 22767, 22769, 27499, 27499,
  ],
};
