import { gql as gqlFn } from '@/graphql/__generated__';

export const allTicketsQuery = gqlFn(/* GraphQL */ `
  query FetchAllTickets($project: ID!) {
    ticketsV2(project: $project) {
      edges {
        node {
          ...TicketPreview
        }
      }
    }
  }
`);

export const singleTicketQuery = gqlFn(/* GraphQL */ `
  query FetchSingleTicket($id: ID!) {
    ticket(id: $id) {
      ...TicketDetails
      project {
        id
      }
    }
  }
`);

export const createTicketStatusMutation = gqlFn(/* GraphQL */ `
  mutation CreateTicketStatus($input: CreateTicketStatusUpdateInput!) {
    createTicketStatusUpdate(input: $input) {
      ticketStatusUpdate {
        ticket {
          ...TicketDetails
        }
      }
    }
  }
`);

export const createTicketMutation = gqlFn(/* GraphQL */ `
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      ticket {
        ...TicketDetails
      }
    }
  }
`);

export const updateTicketMutation = gqlFn(/* GraphQL */ `
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      ticket {
        ...TicketDetails
      }
    }
  }
`);

export const deleteTicketMutation = gqlFn(/* GraphQL */ `
  mutation DeleteTicket($input: DeleteTicketInput!) {
    deleteTicket(input: $input) {
      success
    }
  }
`);

export const exportTicketsMutation = gqlFn(/* GraphQL */ `
  mutation ExportTickets($input: CreateTicketExportInput!) {
    createTicketExport(input: $input) {
      ticketExport {
        file {
          url
          downloadUrl
        }
      }
    }
  }
`);

export const createTicketAttachmentMutation = gqlFn(/* GraphQL */ `
  mutation CreateTicketAttachment($input: CreateTicketAttachmentInput!) {
    createTicketAttachment(input: $input) {
      success
    }
  }
`);

export const deleteTicketAttachmentMutation = gqlFn(/* GraphQL */ `
  mutation DeleteTicketAttachment($input: DeleteTicketAttachmentInput!) {
    deleteTicketAttachment(input: $input) {
      success
    }
  }
`);
