import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export class TenantMemberAddedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TENANT_MEMBER_ADDED;
}

export class TenantMemberDeletedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TENANT_MEMBER_DELETED;
}

export type UserInvitationResendType =
  | 'tenant'
  | 'project_management'
  | 'internal_cg'
  | 'external_cg';

export class UserInvitationResend extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.USER_INVITATION_RESEND;
  public constructor(
    public properties: {
      type: UserInvitationResendType;
      timeInMinutesSinceLastInvitation: number | undefined;
    },
  ) {
    super();
  }
}
