import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const orderTaskStatusMutation = gqlFn(/* GraphQL */ `
  mutation UpdateOrderTaskStatus($id: ID!, $completedAt: DateTime) {
    updateOrderTaskStatus(input: { id: $id, completedAt: $completedAt }) {
      orderTask {
        ...OrderTask
      }
    }
  }
`);

export const orderTaskFragment = gql`
  fragment OrderTask on OrderTaskNode {
    id
    name
    sequenceNumber
    completedAt
  }
`;

export const orderDetailsFragment = gql`
  fragment OrderDetails on OrderNode {
    id
    photos {
      totalCount
    }
    documents {
      totalCount
      edges {
        node {
          ...OrderDocument
        }
      }
    }
    tasks {
      edges {
        node {
          ...OrderTask
        }
      }
    }
    latestStatusReport {
      ...OrderStatusReport
    }
    links {
      ...Link
    }
  }
`;

export const orderPolledDetailsFragment = gql`
  fragment OrderPolledDetails on OrderNode {
    id
    photos {
      totalCount
    }
    documents {
      totalCount
      edges {
        node {
          ...OrderDocument
        }
      }
    }
    tasks {
      edges {
        node {
          ...OrderTask
        }
      }
    }
    links {
      ...Link
    }
  }
`;

export const orderDetailsQuery = gqlFn(/* GraphQL */ `
  query orderDetails($id: ID!) {
    order(id: $id) {
      ...OrderDetails
    }
  }
`);

export const orderPolledDetailsQuery = gqlFn(/* GraphQL */ `
  query orderPolledDetails($id: ID!) {
    order(id: $id) {
      ...OrderPolledDetails
    }
  }
`);

export const orderTasksQuery = gqlFn(/* GraphQL */ `
  query orderTasks($ids: [ID!]!) {
    orders(ids: $ids) {
      id
      tasks {
        edges {
          node {
            ...OrderTask
          }
        }
      }
    }
  }
`);
