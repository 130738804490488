import { OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';

let engine: OrderSchedulingEngine;

export function installLocalOrderSchedulingEngine(newEngine: OrderSchedulingEngine) {
  engine = newEngine;
}

export function useLocalOrderSchedulingEngine() {
  if (!engine) {
    throw new Error(
      "Engine not installed, please call 'installLocalOrderSchedulingEngine' during setup.",
    );
  }
  return engine;
}

/**
 * Provides functions of engine utils with fallback in case
 * the engine isn't initialized yet. Should only be used for
 * readonly data as fallback calculation can lead to scheduling
 * issues because the result can differ from engine result.
 * @returns
 */
export function useLocalOrderSchedulingEngineUtils() {
  const engine = useLocalOrderSchedulingEngine();

  return {
    computeWorkingTimeBetween(
      endDate: Date,
      startDate: Date,
      calendarId: string | null,
    ): number | null {
      if (!engine.isInitialized) return null;

      return engine.utils.computeWorkingTimeBetween(endDate, startDate, calendarId);
    },
  };
}
