import { IS_DEV_ENV, IS_PROD_ENV } from './config';

export const ENABLE_PPP = IS_DEV_ENV;

export function useFeatureToggles() {
  const toggles = {
    ENABLE_PRODUCTION_LOGGING_ON_DEV: false,
    ENABLE_STAGING_LOGGING_ON_DEV: false,
    ENABLE_PPP,
    enableBackendExportFilter: (user: { email: string; id: string }) => {
      return user.email.endsWith('@koppla.de') || user.id === 'VXNlck5vZGU6NjEwMQ==';
    },
  };
  if (IS_PROD_ENV) {
    return toggles;
  }
  Object.keys(toggles).forEach((toggleKey) => {
    try {
      const localStorageValue = localStorage.getItem(toggleKey);
      if (localStorageValue !== null) {
        toggles[toggleKey] = localStorageValue === 'true';
      }
    } catch {
      // Local Storage is disabled.
    }
  });
  return toggles;
}
