import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, Ref, unref } from 'vue';

import { useResendMembershipInvitation } from '@/features/memberships/composables/resendInvitation';
import {
  CreateMembershipMutation,
  CreateMembershipMutationVariables,
  DeleteMembershipMutation,
  DeleteMembershipMutationVariables,
  MembershipQuery,
  MembershipQueryVariables,
  MembershipsOfUserQuery,
  MembershipsOfUserQueryVariables,
  MembershipsQuery,
  MembershipsQueryVariables,
  UpdateMembershipMutation,
  UpdateMembershipMutationVariables,
} from '@/graphql/__generated__/graphql';
import MembershipsAllQuery from '@/graphql/membership/All.gql';
import MembershipsOwnQuery from '@/graphql/membership/AllOwn.gql';
import MembershipCreate from '@/graphql/membership/Create.gql';
import MembershipDelete from '@/graphql/membership/Delete.gql';
import MembershipDetailQuery from '@/graphql/membership/Detail.gql';
import MembershipUpdate from '@/graphql/membership/Update.gql';
import { sortObjectsByKey } from '@/helpers/utils/arrays';
import { omitKeys } from '@/helpers/utils/objects';
import { AppApolloClient } from '@/interfaces/graphql';
import { MembershipRepository } from '@/interfaces/repositories';
import {
  DeleteMutationResult,
  Entity,
  MutationResult,
  OrderByCondition,
  QueryAllResult,
  QueryResult,
} from '@/interfaces/repositories/base';
import {
  CreateMembershipVariables,
  FindAllMembershipVariables,
  Membership,
  MembershipWithTenant,
  MembershipWithUser,
  UpdateMembershipVariables,
} from '@/interfaces/repositories/memberships';
import { useApolloClient } from '@/plugins/apollo';
import { useDefaultMutationOnEntity, useDefaultQueryOnEntity } from '@/repositories/utils/defaults';
import {
  flattenNodeConnection,
  getOrderByArgument,
  useFetchAllResult,
} from '@/repositories/utils/fetchAll';
import { getTypedMutate, useCalledIndicator } from '@/repositories/utils/helper';

export class GraphQLMembershipRepository implements MembershipRepository {
  public constructor(
    private client: AppApolloClient,
    private disableCache = false,
  ) {}

  public fetchTenantMembership(tenantId: string): Promise<Membership | undefined> {
    const client = useApolloClient();
    return client
      .query<MembershipsOfUserQuery, MembershipsOfUserQueryVariables>({
        query: MembershipsOwnQuery,
        variables: {},
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const membership = result.data.memberships?.edges.find(
          (edge) => edge.node.tenant.id === tenantId,
        );
        return membership?.node;
      });
  }

  public useCreate(
    vars?: Ref<CreateMembershipVariables>,
  ): MutationResult<CreateMembershipVariables> {
    const variables: Ref<CreateMembershipMutationVariables> | undefined = vars;

    const { onMembershipCreated } = useResendMembershipInvitation();

    const { mutate, error, loading } = useMutation<
      CreateMembershipMutation,
      CreateMembershipMutationVariables
    >(MembershipCreate, () => ({
      variables: variables?.value,
    }));

    return {
      mutate: (data?: CreateMembershipVariables) => {
        return mutate(data).then((result) => {
          /**
           * Reinvite shouldn't be possible in the same session
           * the user was invited.
           */
          if (result?.data?.createMembership?.membership) {
            onMembershipCreated(result?.data?.createMembership.membership);
          }

          return result;
        });
      },
      loading,
      error,
    };
  }

  public useUpdate(
    id: string,
    vars: Ref<UpdateMembershipVariables>,
  ): MutationResult<UpdateMembershipVariables> {
    const variables: Ref<UpdateMembershipMutationVariables> = computed(() => ({
      id,
      ...vars.value,
    }));

    const { mutate, error, loading } = useDefaultMutationOnEntity<
      UpdateMembershipMutation,
      UpdateMembershipMutationVariables
    >(MembershipUpdate, variables);

    return {
      mutate: (data?: UpdateMembershipVariables) => {
        return getTypedMutate<UpdateMembershipVariables, UpdateMembershipMutationVariables>(mutate)(
          data,
        );
      },
      loading,
      error,
    };
  }

  public useDelete(id: string): DeleteMutationResult {
    const variables: Ref<DeleteMembershipMutationVariables> = computed(() => ({
      id: unref(id),
    }));

    const { mutate, error, loading } = useDefaultMutationOnEntity<
      DeleteMembershipMutation,
      DeleteMembershipMutationVariables
    >(MembershipDelete, variables);

    return {
      mutate: (vars?: DeleteMembershipMutationVariables) => {
        return mutate(vars);
      },
      loading,
      error,
    };
  }

  public fetchOne(id: string): QueryResult<MembershipWithUser | null, Entity> {
    const variables: MembershipQueryVariables = { id };

    const { result, loading, error, refetch } = useDefaultQueryOnEntity<
      MembershipQuery,
      MembershipQueryVariables
    >(MembershipDetailQuery, variables);

    const { called } = useCalledIndicator(result);

    return {
      result: computed(() => result.value?.membership ?? null),
      loading,
      error,
      refetch,
      called,
    };
  }

  public fetchAll(
    filterArgs: Ref<FindAllMembershipVariables>,
    orderBy?: OrderByCondition,
  ): QueryAllResult<MembershipWithUser> {
    const {
      result: rawResult,
      loading,
      error,
      refetch,
    } = useQuery<MembershipsQuery, MembershipsQueryVariables>(
      MembershipsAllQuery,
      () => ({
        ...omitKeys(filterArgs.value, ['tenantId']),
        tenant: filterArgs.value.tenantId,
        orderBy: getOrderByArgument(orderBy),
      }),
      {
        fetchPolicy: this.disableCache ? 'no-cache' : 'cache-and-network',
      },
    );

    const result = useFetchAllResult<
      MembershipsQuery,
      NonNullable<MembershipsQuery['memberships']>,
      MembershipWithUser
    >(rawResult, 'memberships', (data) => {
      const flattened = flattenNodeConnection(data);
      return sortObjectsByKey(flattened, undefined, {
        top: 'user',
        nested: 'lastName',
      }).sort((a, b) => {
        // Sort all inactive users, i.e. invited users, to the front.
        if (a.user.isActive && !b.user.isActive) return 1;
        if (!a.user.isActive && b.user.isActive) return -1;
        return 0;
      });
    });

    const { called } = useCalledIndicator(rawResult);

    return { ...result, loading, error, refetch, called };
  }

  public fetchAllOwn(): QueryResult<readonly MembershipWithTenant[]> {
    const { result, loading, error, refetch } = useQuery<
      MembershipsOfUserQuery,
      MembershipsOfUserQueryVariables
    >(MembershipsOwnQuery);

    const { called } = useCalledIndicator(result);

    const memberships = computed(() =>
      flattenNodeConnection(result.value?.memberships).sort((a, b) =>
        a.tenant.name.toUpperCase() > b.tenant.name.toUpperCase() ? 1 : -1,
      ),
    );

    return {
      result: memberships,
      loading,
      called,
      error,
      refetch,
    };
  }
}
